import React, {  Suspense } from "react";
import { Switch,Redirect} from "react-router-dom";
import PrivateRoute from 'components/PrivateRoute'
import { strings } from "res";
import Loading from 'components/shared-components/Loading';

// Pages
import Dashboard from "./dashboard"
import Batch from "./batch"
import Master from "./master"
import Product from "./product"
import Formula from "./formula"
import Bahan from "./bahan"
import Form from "./form"
import BatchReport from "./batch-report"
import FormReport from "./form-report"
import Pengguna from "./pengguna"
import Report from "./report"
import History from "./history"

// Detail
import DetailBatch from "./detail-batch"
import TambahBatch from "./tambah-batch-report"
import DetailProduct from "./detail-product"
import DetailPengguna from "./detail-pengguna"
import DetailFormula from "./detail-formula"
import DetailForm from "./detail-form"
import DetailFormItem from "./detail-form-item"
import DetailBahan from "./detail-bahan"
import DetailHistory from "./detail-history"
import DetailBatchReport from "./detail-batch-report"
import DetailFormReport from "./detail-form-report"
import Settings from "./settings"

export const AppViews = ({match}) => {

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <PrivateRoute path={`${strings.navigation.path.dashboard}`} component={Dashboard} />
        <PrivateRoute path={`${strings.navigation.path.batch}`} component={Batch} />
        <PrivateRoute path={`${strings.navigation.path.master_data}`} component={Master} />
        <PrivateRoute path={`${strings.navigation.path.produk}`} component={Product} />
        <PrivateRoute path={`${strings.navigation.path.formula}`} component={Formula} />
        <PrivateRoute path={`${strings.navigation.path.form}`} component={Form} />
        <PrivateRoute path={`${strings.navigation.path.bahan}`} component={Bahan} />
        <PrivateRoute path={`${strings.navigation.path.batch_report}`} component={BatchReport} />
        <PrivateRoute path={`${strings.navigation.path.form_report}`} component={FormReport} />
        <PrivateRoute path={`${strings.navigation.path.report}`} component={Report} />
        <PrivateRoute path={`${strings.navigation.path.pengguna}`} component={Pengguna} />
        <PrivateRoute path={`${strings.navigation.path.history}`} component={History} />

        <PrivateRoute path={`${strings.navigation.path.tambah_batch}`} component={TambahBatch} />
        <PrivateRoute path={`${strings.navigation.path.detail_form_report}`} component={DetailFormReport} />

        <PrivateRoute path={`${strings.navigation.path.detail_batch}`} component={DetailBatch} />
        <PrivateRoute path={`${strings.navigation.path.detail_product}`} component={DetailProduct} />
        <PrivateRoute path={`${strings.navigation.path.detail_formula}`} component={DetailFormula} />
        <PrivateRoute path={`${strings.navigation.path.detail_form}`} component={DetailForm} />
        <PrivateRoute path={`${strings.navigation.path.detail_form_item}`} component={DetailFormItem} />
        <PrivateRoute path={`${strings.navigation.path.detail_bahan}`} component={DetailBahan} />
        <PrivateRoute path={`${strings.navigation.path.detail_history}`} component={DetailHistory} />
        <PrivateRoute path={`${strings.navigation.path.detail_pengguna}`} component={DetailPengguna} />
        <PrivateRoute path={`${strings.navigation.path.detail_batch_report}`} component={DetailBatchReport} />
        <PrivateRoute path={`${strings.navigation.path.detail_form_report}`} component={DetailFormReport} />

        {/* DETAIL */}
        <PrivateRoute path={`${strings.navigation.path.settings}`} component={Settings} />
        <Redirect from={`${match.url}`} to={`${strings.navigation.path.dashboard}`} />

      </Switch>
    </Suspense>
  )
}

export default AppViews;

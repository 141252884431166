import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllFormula = createAsyncThunk(
	'Formula/fetchAllFormula',
	async (_, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.FORMULA)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const searchFormula = createAsyncThunk(
	'Formula/searchFormula',
	async (title, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.FORMULA}/search?code=${title}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchOneFormula = createAsyncThunk(
	'Formula/fetchOneFormula',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.FORMULA}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const addFormula = createAsyncThunk(
	'Formula/addFormula',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('post', URLS.FORMULA, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const deleteFormula = createAsyncThunk(
	'Formula/deleteFormula',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('delete', `${URLS.FORMULA}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const updateFormula = createAsyncThunk(
	'Formula/updateFormula',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', URLS.FORMULA, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const initialState = {
	loading: {
		query: false,
		mutation: false
	},
	filter: {
		q: ''
	},
	list: [],
	selected: {},
	selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
	state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const FormulaSlice = createSlice({
	name: 'Formula',
	initialState,
	reducers: {
		setAppliedSearchText: (state, action) => {
			state.filter.q = action.payload
		},
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAllFormula.pending, startLoadingQuery)
			.addCase(fetchAllFormula.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllFormula.rejected, stopLoadingQuery)
		builder
			.addCase(searchFormula.pending, startLoadingQuery)
			.addCase(searchFormula.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(searchFormula.rejected, stopLoadingQuery)

		builder
			.addCase(fetchOneFormula.pending, startLoadingQuery)
			.addCase(fetchOneFormula.rejected, stopLoadingQuery)
			.addCase(fetchOneFormula.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(updateFormula.pending, startLoadingQuery)
			.addCase(updateFormula.rejected, stopLoadingQuery)
			.addCase(updateFormula.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(deleteFormula.pending, startLoadingMutation)
			.addCase(deleteFormula.fulfilled, stopLoadingMutation)
			.addCase(deleteFormula.rejected, stopLoadingMutation)
	}
});


export const { setSelectedRows, setAppliedSearchText } = FormulaSlice.actions;

export default FormulaSlice.reducer;
export const header_penimbangan = (tabel) =>{
    return(
        `<figure class=\"table\">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p><strong>Nama Produk: .......... </strong></p>
                            <p><strong>Kode Produk: .......... </strong></p>
                            <p><strong>Nama Formula: .......... </strong></p>
                        </td>
                        <td>
                            <p><strong>Tanggal Formula Fix: .......... </strong></p>
                            <p><strong>Skala Produksi: .......... </strong></p>
                            <p><strong>Nomor Batch: .......... </strong></p>
                        </td>
                        <td>
                            <p><strong>Ukuran: .......... </strong></p>
                            <p><strong>Tangki: ..........</strong></p>
                            <p><strong>Prioritas: .......... </strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td>Disetujui Oleh</td>
                        <td>Dicek Oleh</td>
                        <td>Dibuat Oleh</td>
                    </tr>
                    <tr>
                        <td>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        </figure>${tabel}`
    )
}

export const header_penimbangan_filled = (data,tabel) =>{
    return(
        `<figure class=\"table\">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p><strong>Nama Produk: ${data.nama_produk || "........."}</strong></p>
                            <p><strong>Kode Produk: ${data.kode_produk  || "........."}</strong></p>
                            <p><strong>Nama Formula: ${data.nama_formula || "........."}</strong></p>
                        </td>
                        <td>
                            <p><strong>Tanggal Formula Fix: ${data.tanggal_formula_fix  || "........."}</strong></p>
                            <p><strong>Skala Produksi: ${data.skala_produksi  || "........."}</strong></p>
                            <p><strong>Nomor Batch: ${data.nomor_batch  || "........."}</strong></p>
                        </td>
                        <td>
                            <p><strong>Ukuran:${data.ukuran  || "........."}</strong></p>
                            <p><strong>Tangki:${data.tangki  || "........."}</strong></p>
                            <p><strong>Prioritas: ${data.prioritas  || "........."}</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td>Disetujui Oleh</td>
                        <td>Dicek Oleh</td>
                        <td>Dibuat Oleh</td>
                    </tr>
                    <tr>
                        <td>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        </figure>${tabel}`
    )
}

export const tabel_penimbangan = (rows)=>{
    return(
        `<figure class=\"table\">
            <table>
                <thead>
                    <tr>
                        <th><strong>Fase</strong></th>
                        <th><strong>Kode Bahan</strong></th>
                        <th><strong>Bahan Baku</strong></th>
                        <th><strong>Skala (Gram)</strong></th>
                        <th><strong>Aktual</strong></th>
                        <th><strong>Lot No Internal</strong></th>
                        <th><strong>Dilakukan Oleh</strong></th>
                        <th><strong>Dicek Oleh</strong></th>
                    </tr>
                </thead>
                <tbody>
                    ${rows}
                </tbody>
            </table>
        </figure>`
    )
}

export const row = (Fase,Kode,BahanBaku,Skala,Aktual,Lot,Dilakukan,Dicek) => {
    return (
        `<tr>
            <td>${Fase}</td>
            <td>${Kode}</td>
            <td>${BahanBaku}</td>
            <td>${Skala}</td>
            <td>${Aktual}</td>
            <td>${Lot}</td>
            <td>${Dilakukan}</td>
            <td>${Dicek}</td>
        </tr>`
    )
}

import { Col, Row, Select, message } from 'antd';
import React, { useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Form, Input } from 'antd';
import { register } from '../../../redux/features/auth'
import { fetchOnePengguna, updatePengguna, deletePengguna } from '../../../redux/features/pengguna'
import { useEffect } from 'react';
import jwt_decode from 'jwt-decode'
import { useDispatch } from 'react-redux';

const roles = ['admin', "rd_staff", "chief_rd", "rd_spv", "chief_produksi", "chief_produksi_pengemasan", "chief_qc", "warehouse_manager", "quality_control_spv", "production_development_manager", "quality_regulatory_manager", "qa_spv", "operator_penimbangan", "operator_pengolahan", "operator_pengemasan", "qa_staff"]

const selectStyle = {
  width: "100%",
  color: "black",
  backgroundColor: "white"
};

const rules = [
  {
    required: true,
    message: 'Wajib Diisi!',
  }
]

const Option = Select

export const SETTINGS = () => {

  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const [id, setId] = useState(null)
  const [role, setRole] = useState('')

  const handleChange = (value) => {
    setRole(value)
    console.log(`selected ${value}`);
  };

  const onFinish = async (values) => {
    try {
      if (id) {
        updateNewUser({
          ...values,
          _id: id
        })
      } else {
        createNewUser(values)
      }
      history.push("/app/pengguna")
    } catch (err) {
      message.error("Something is wrong!")
    }
  };

  const createNewUser = async (values) => {
    try {
      await dispatch(register({
        ...values,
        role
      }))
      message.success("Pengguna baru ditambahkan!")
    } catch (err) {
      message.error("Something is wrong!")
    }
  }

  const updateNewUser = async (values) => {
    try {
      const response = await dispatch(updatePengguna({
        ...values,
        role,
        id: id
      }))
      console.log(response)
      message.success("Pengguna baru diupdate!")
    } catch (err) {
      message.error("Something is wrong!")
    }
  }

  const getUserProfile = async (id) => {
    try {
      const profile = await dispatch(fetchOnePengguna(id)).unwrap()
      form.setFieldsValue(profile[0])
      setRole(profile[0].role)
    } catch (err) {
      message.error("Something is wrong!")
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    let token = localStorage.getItem("token")
    let user = jwt_decode(token)
    const itemId = location.state?.id
    if (itemId) {
      setId(itemId)
      getUserProfile(itemId)
    }
    console.log("Dashboard is running!")
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Atur Pengguna</h2>
          <p>Atur pengguna dan privilege pengguna</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Nama Lengkap"
                name="fullname"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Role"
                name="role"
                rules={rules}
              >
                <Select
                  defaultValue={roles[0]}
                  style={selectStyle}
                  onChange={handleChange}
                >
                  {
                    roles.map((role) => {
                      return <Option value={role} >{role}</Option>
                    })
                  }
                </Select>
              </Form.Item>

              {!id && (
                <>
                  <Form.Item
                    label="Set New Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label="Confirm New Password"
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </>
              )}

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  {id ? "Update Pengguna" : "Tambah Pengguna"}
                </Button>
              </Form.Item>
            </Form>
            {id && (
              <>
                <Button onClick={() => {
                  dispatch(deletePengguna(id))
                  message.error("Pengguna dihapus!")
                  history.push("/app/pengguna")
                }} type="danger" htmlType="submit" style={{ width: "100%", color: "white" }}>
                  Delete Pengguna
                </Button>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default SETTINGS
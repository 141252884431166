import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllProduct = createAsyncThunk(
	'Product/fetchAllProduct',
	async (_, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.PRODUCT)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const searchProduct = createAsyncThunk(
	'Product/searchProduct',
	async (query, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.PRODUCT + '/search'+`?name=${query}`)
			console.log(response)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchOneProduct = createAsyncThunk(
	'Product/fetchOneProduct',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.PRODUCT}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const addProduct = createAsyncThunk(
	'Product/addProduct',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('post', URLS.PRODUCT, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const deleteProduct = createAsyncThunk(
	'Product/deleteProduct',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('delete', `${URLS.PRODUCT}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const updateProduct = createAsyncThunk(
	'Product/updateProduct',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', URLS.PRODUCT, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const initialState = {
	loading: {
		query: false,
		mutation: false
	},
	filter: {
		q: ''
	},
	list: [],
	selected: {},
	selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
	state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const ProductSlice = createSlice({
	name: 'Product',
	initialState,
	reducers: {
		setAppliedSearchText: (state, action) => {
			state.filter.q = action.payload
		},
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAllProduct.pending, startLoadingQuery)
			.addCase(fetchAllProduct.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllProduct.rejected, stopLoadingQuery)

		builder
			.addCase(searchProduct.pending, startLoadingQuery)
			.addCase(searchProduct.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(searchProduct.rejected, stopLoadingQuery)

		builder
			.addCase(fetchOneProduct.pending, startLoadingQuery)
			.addCase(fetchOneProduct.rejected, stopLoadingQuery)
			.addCase(fetchOneProduct.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(updateProduct.pending, startLoadingQuery)
			.addCase(updateProduct.rejected, stopLoadingQuery)
			.addCase(updateProduct.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(deleteProduct.pending, startLoadingMutation)
			.addCase(deleteProduct.fulfilled, stopLoadingMutation)
			.addCase(deleteProduct.rejected, stopLoadingMutation)
	}
});


export const { setSelectedRows, setAppliedSearchText } = ProductSlice.actions;

export default ProductSlice.reducer;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllBatchReport = createAsyncThunk(
	'BatchReport/fetchAllBatchReport',
	async (query, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.BATCH_REPORT,query)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchAllBatchReportCount = createAsyncThunk(
	'BatchReport/fetchAllBatchReportCount',
	async (_, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.BATCH_REPORT + "/count")
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchAllBatchReportByDate = createAsyncThunk(
	'BatchReport/fetchAllBatchReportByDate',
	async (date, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.BATCH_REPORT + '/date/' + date)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const searchBatchReport = createAsyncThunk(
	'BatchReport/searchBatchReport',
	async (title, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.BATCH_REPORT}/search?name=${title}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchOneBatchReport = createAsyncThunk(
	'BatchReport/fetchOneBatchReport',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.BATCH_REPORT}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const addBatchReport = createAsyncThunk(
	'BatchReport/addBatchReport',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('post', URLS.BATCH_REPORT, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const updateBatchReport = createAsyncThunk(
	'BatchReport/updateBatchReport',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', URLS.BATCH_REPORT, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const deleteBatchReport = createAsyncThunk(
	'BatchReport/deleteBatchReport',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('delete', `${URLS.BATCH_REPORT}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const initialState = {
	loading: {
		query: false,
		mutation: false
	},
	filter: {
		q: ''
	},
	list: [],
	selected: {},
	selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
	state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const BatchReportSlice = createSlice({
	name: 'BatchReport',
	initialState,
	reducers: {
		setAppliedSearchText: (state, action) => {
			state.filter.q = action.payload
		},
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAllBatchReport.pending, startLoadingQuery)
			.addCase(fetchAllBatchReport.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllBatchReport.rejected, stopLoadingQuery)
		builder
			.addCase(fetchAllBatchReportCount.pending, startLoadingQuery)
			.addCase(fetchAllBatchReportCount.fulfilled, (state, action) => {
				state.selected = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllBatchReportCount.rejected, stopLoadingQuery)
		builder
			.addCase(fetchAllBatchReportByDate.pending, startLoadingQuery)
			.addCase(fetchAllBatchReportByDate.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllBatchReportByDate.rejected, stopLoadingQuery)
		builder
			.addCase(fetchOneBatchReport.pending, startLoadingQuery)
			.addCase(fetchOneBatchReport.rejected, stopLoadingQuery)
			.addCase(fetchOneBatchReport.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(updateBatchReport.pending, startLoadingQuery)
			.addCase(updateBatchReport.rejected, stopLoadingQuery)
			.addCase(updateBatchReport.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(searchBatchReport.pending, startLoadingQuery)
			.addCase(searchBatchReport.rejected, stopLoadingQuery)
			.addCase(searchBatchReport.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(deleteBatchReport.pending, startLoadingMutation)
			.addCase(deleteBatchReport.fulfilled, stopLoadingMutation)
			.addCase(deleteBatchReport.rejected, stopLoadingMutation)
	}
});


export const { setSelectedRows, setAppliedSearchText } = BatchReportSlice.actions;

export default BatchReportSlice.reducer;
import { Button, Card, Col, Form, Input, Modal, Row, message, Table, Select, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addFormula, fetchOneFormula, deleteFormula, updateFormula } from 'redux/features/formula';
import { v4 as uuidv4 } from 'uuid';
import { addHistory } from 'redux/features/history';
import { addBahan, searchBahan } from 'redux/features/bahan';
import moment from 'moment-timezone';
import { fetchAllProduct } from 'redux/features/product'
import { addFormulaItem, deleteFormulaItem } from 'redux/features/formula-item';

const { Search } = Input;
const { Option } = Select

const buttonStlye = { border: "0px", width: "50%", margin: "10px" }

const rules = [
    {
        required: true,
        message: 'Wajib Diisi!',
    }
]

export const DETAIL_FORMULA = () => {

    const [form] = Form.useForm()
    const location = useLocation()
    const history = useHistory()
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedProductId, setSelectedProductId] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [persentase, setPersentase] = useState(0)
    const [tanggal, setTanggal] = useState('')
    const [formula, setFormula] = useState(null)
    const [daftarBahan, setDaftarBahan] = useState([])
    const [selectedBahan, setSelectedBahan] = useState(null)
    const [daftarProducts, setDaftarProducts] = useState([])
    const [selectedBahanCode, setSelectedBahanCode] = useState(null)
    const [selectedBahanName, setSelectedBahanName] = useState(null)
    const [selectedPersentase, setSelectedPersentase] = useState(0)
    const [id, setId] = useState("")
    const [visible, setVisible] = useState(false)
    const [tambahVisible, setTambahVisible] = useState(false)
    const dispatch = useDispatch()

    const handleSearch = (newValue) => {
        // fetch(newValue, setData);
    }

    const handleChange = (newValue) => {
        // getProductById(newValue)
        setSelectedProduct(newValue)
    }

    const handleDeleteFormula = async (id) => {
        await dispatch(deleteFormula(id))
        setVisible(false)
        message.success("Proses Hapus data...")
        setTimeout(() => {
            history.push({
                pathname: '/app/formula'
            })
        }, 1000)
    }

    const getAllProducts = async () => {
        try {
            const products = await dispatch(fetchAllProduct()).unwrap()
            setDaftarProducts(products)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const handleDelete = async () => {
        try {
            handleDeleteFormula(id)
        } catch (err) {
            console.log(err)
        }
    };

    const handleDeleteFormulaItem = async (idBahan) => {
        try {
            await dispatch(deleteFormulaItem(idBahan)).unwrap()
            setId(id)
            message.success("Proses Hapus Bahan...")
            getDataById(id)
        } catch (err) {
            console.log(err)
        }
    };

    const tambahBahan = async () => {
        try {
            await dispatch(addFormulaItem({
                name: selectedBahanName,
                bahanCode: selectedBahanCode,
                percentage: selectedPersentase / 100 || 0,
                formulaCode: formula.code
            })).unwrap()
            setId(id)
            getDataById(id)
            setTambahVisible(false)
        } catch (err) {
            console.log(err)
        }
    }

    const handleCancel = () => {
        setVisible(false);
    };

    const handleCancelTambahBahan = () => {
        setTambahVisible(false)
    }

    const onFinish = async (values) => {
        if (id) {
            updateItem(values)
        } else {
            addItem(values)
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const addItem = async (values) => {
        try {
            await dispatch(addFormula({ ...values, productCode:uuidv4() }))
            message.success("Formula Berhasil Dibuat! Kembali Ke Halaman Depan...")
            setTimeout(() => {
                history.push({
                    pathname: '/app/formula'
                })
            }, 1000)
        }
        catch (err) {
            console.log(err)
            message.error("Kode produk harus unik & gramasi harus angka!")
        }
    }

    const updateItem = async (values) => {
        try {
            await dispatch(updateFormula(
                {...values,
                    id: id,
                    tanggal_formula:tanggal,
                    productCode:uuidv4()
                }))
            message.success("Formula berhasil diupdate!")
            setTimeout(() => {
                history.push({
                    pathname: '/app/formula'
                })
            }, 1000)
        }
        catch (err) {
            console.log(err)
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const searchByTitle = async (title) => {
        try {
            const selected = await dispatch(searchBahan(title)).unwrap()
            setSelectedBahan(`${selected[0].name} - ${selected[0].code}`)
            setSelectedBahanCode(selected[0].code)
            setSelectedBahanName(selected[0].name)
        } catch (err) {
            console.log(err)
        }
    }

    const getDataById = async (id) => {
        try {
            setPersentase(0)
            const item = await dispatch(fetchOneFormula(id)).unwrap()
            form.setFieldsValue(item)
            setFormula(item)
            setTanggal(item.tanggal_formula)
            setDaftarBahan(item.formulaItems)
            await item.formulaItems.map(item => {
                setPersentase(percentage => parseFloat(percentage) + parseFloat(item.percentage))
            })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAllProducts()
        const itemId = location.state?.id
        if (itemId) {
            setId(itemId)
            getDataById(itemId)
        }
    }, [])


    const tableColumns = [
        {
            title: () => <div className="text-center">Kode Bahan</div>,
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.formula?.bahanCode}</div>)
            },
        },
        {
            title: () => <div className="text-center">Nama Bahan</div>,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.formula?.bahan[0]?.name}</div>)
            },
        },
        {
            title: () => <div className="text-center">Persentase</div>,
            dataIndex: 'percentage',
            key: 'percentage',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{Number(parseFloat(record.percentage * 100)).toFixed(9)}%</div>)
            },
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'detail',
            render: (_, record) => {
                return (
                    <div style={{ display: "flex" }}>
                        {/* <Button type="primary" style={{ ...buttonStlye }} htmlType="submit" onClick={() => {

                        }} block>
                            Update
                        </Button> */}
                        <Button type="danger" style={{ ...buttonStlye, color: "white", width: "100%" }} htmlType="submit" onClick={() => {
                            handleDeleteFormulaItem(record.formula?._id)
                        }} block>
                            Delete
                        </Button>
                    </div>
                )
            },
        }
    ]

    return (
        <>
            <Modal
                title="Hapus Data"
                visible={visible}
                onOk={handleDelete}
                onCancel={handleCancel}
            >
                <p>Anda yakin ingin melakukan aksi ini?</p>
            </Modal>
            <Modal
                title="Tambah Bahan"
                visible={tambahVisible}
                onOk={tambahBahan}
                onCancel={handleCancelTambahBahan}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <h5>Cari Bahan Total Skala Maksimal 1.0 (Skala Skarang: {parseFloat(persentase).toFixed(3) * 100})%</h5>
                        <Search
                            placeholder="Cari Berdasarkan Nama Bahan"
                            onSearch={searchByTitle}
                            style={{
                                width: "100%",
                            }}
                        />
                        <b style={{ color: selectedBahan ? 'green' : 'red' }} >{selectedBahan || "Belum Dipilih"}</b>
                        <h5 style={{ marginTop: "10px" }}>Tentukan Skala Dalam Persen</h5>
                        <Input
                            placeholder="Persentase"
                            label="Persentase"
                            name='persentase'
                            value={selectedPersentase}
                            onChange={(values) => {
                                // const value = parseFloat(values.target.value)
                                setSelectedPersentase(values.target.value)
                            }}
                            style={{
                                width: "100%"
                            }}
                        />
                    </Col>
                </Row>
            </Modal>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Formula</h2>
                    <p>Tambah atau edit formula sesuaikan dengan kebutuhan anda</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Form
                            form={form}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Kode Formula"
                                name="code"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            {/* <Form.Item
                                label="Pilih Produk"
                                name="product"
                                rules={rules}
                            >
                                <Select
                                    showSearch
                                    value={selectedProduct}
                                    placeholder={"Pilih Produk"}
                                    style={{ width: "100%", color: "black" }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    onChange={handleChange}>
                                    {daftarProducts.map(product => {
                                        return (
                                            <Option value={`${product.name}`} >{`${product.formula?.code ? 'ADA':'BELUM ADA'} - ${product.code} - ${product.name}`}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item> */}

                            <Form.Item
                                label={`Tanggal Formula Terakhir: ${tanggal}`}
                                // name="tanggal_formula"
                                rules={rules}
                            >
                                <DatePicker style={{width:"100%"}} format={'DD-MM-YYYY'} onChange={(date)=>{
                                    setTanggal(moment(date).format("DD-MM-YYYY"))
                                }} />
                            </Form.Item>

                            <Form.Item
                                label="Gramasi"
                                name="gramasi"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            {/* <Form.Item
                                label="Kode Produk"
                                name="productCode"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item> */}

                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
                                    {id ? "Update" : "Tambah"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            {id && (
                <>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <h2>Total Skala Bahan {parseFloat(persentase).toFixed(3) * 100} %</h2>
                            <p>Semua bahan di formula ini</p>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Card>
                                <div style={{ display: "flex" }}>
                                    <Input
                                        placeholder="Cari Berdasarkan Nama"
                                        onChange={() => { }}
                                        style={{
                                            width: "80%",
                                            margin: "10px"
                                        }}
                                    />
                                    <Button type="primary" style={{ margin: "10px" }} onClick={() => { }}>Cari</Button>
                                    <Button type="primary" style={{ margin: "10px" }} onClick={() => { setTambahVisible(true) }}>Tambah Bahan</Button>
                                </div>
                                <Table
                                    className="no-border-last"
                                    columns={tableColumns}
                                    loading={isLoading}
                                    dataSource={daftarBahan}
                                    rowKey='id'
                                />
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Button type="danger" style={{ border: "0px", color: "white" }} htmlType="submit" onClick={() => {
                                setVisible(true)
                            }} block>
                                Hapus
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px", color: "white", marginTop: "10px" }} htmlType="submit" onClick={() => {
                        history.push("/app/formula")
                    }} block>
                        Kembali Ke Halaman Utama
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default DETAIL_FORMULA
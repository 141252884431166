import { Button, Card, Col, DatePicker, Select, Input, Row, Table, message } from 'antd';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllByDate, fetchAllHistory } from 'redux/features/history';
import moment from 'moment'

const Option = Select

export const History = () => {
    const history = useHistory()
    const [isLoading] = useState(false)
    const dispatch = useDispatch()
    const [query, setQuery] = useState('')
    const [isReset, setReset] = useState(false)
    const [list, setList] = useState([])

    const getData = async () => {
        try {
            const response = await dispatch(fetchAllHistory({
                tanggal_dibuat:moment().format("DD-MM-YYYY")
            })).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const setTanggalHistory = (value) => {
        setQuery(prevQuery => ({
            ...prevQuery,
            tanggal_dibuat: moment(value).format("DD-MM-YYYY")
        }))
    }

    const setJenisHistory = (value) => {
        setQuery(prevQuery => ({
            ...prevQuery,
            jenis: value
        }))
    }

    const setKodeItemHistory = (event) => {
        event.persist();
        setQuery(prevQuery => ({
            ...prevQuery,
            entity_id: event?.target?.value || ""
        }))
    }

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: () => <div className="text-center">Date</div>,
            dataIndex: 'date',
            key: 'date',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{moment.unix(record?.date).format('DD-MM-YYYY')}</div>)
            },
        },
        {
            title: () => <div className="text-center">Jam</div>,
            dataIndex: 'date',
            key: 'date',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{moment.unix(record?.date).format('hh:mm:ss')}</div>)
            },
        },
        {
            title: () => <div className="text-center">Kode</div>,
            dataIndex: 'entity_id',
            key: 'entity_id',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.entity_id}</div>)
            },
        },
        {
            title: () => <div className="text-center">Jenis</div>,
            dataIndex: 'jenis',
            key: 'jenis',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.jenis}</div>)
            },
        },
        {
            title: () => <div className="text-center">Catatan</div>,
            dataIndex: 'catatan',
            key: 'catatan',
            render: (_, record) => {
                if(record.catatan === "Update"){
                    return (<div style={{ textAlign: "center",color:"orange" }}><b>{record.catatan}</b></div>)
                }
                if(record.catatan === "Create"){
                    return (<div style={{ textAlign: "center",color:"green" }}><b>{record.catatan}</b></div>)
                }
                if(record.catatan === "Delete"){
                    return (<div style={{ textAlign: "center",color:"red" }}><b>{record.catatan}</b></div>)
                }
            },
        },
        {
            title: () => <div className="text-center">Event</div>,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.name}</div>)
            },
        },
        {
            title: () => <div className="text-center">Dibuat Oleh</div>,
            dataIndex: 'writtenBy',
            key: 'writtenBy',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.writtenBy?.username}</div>)
            },
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'detail',
            render: (_, record) => {
                return (
                    <Button type="primary" style={{ border: "0px", width: "100%" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-history',
                            state: {
                                id: record._id
                            }
                        })
                    }} block>
                        Detail
                    </Button>
                )
            },
        }
    ];

    const onSearch = async () => {
        try {
            const response = await dispatch(fetchAllHistory(query)).unwrap()
            setList(response)
            setQuery({})
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Semua History di Tanggal {moment().format('DD-MM-YYYY')}</h2>
                    <p>Semua form yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <DatePicker
                                placeholder="Cari Berdasarkan Tanggal"
                                onChange={setTanggalHistory}
                                format="DD-MM-YYYY"
                                style={{
                                    width: "50%",
                                    marginRight: "2%"
                                }}
                            />
                            <Select style={{
                                width: "20%",
                                color:"black",
                                marginRight: "2%"
                            }} onChange={setJenisHistory} placeholder='Jenis History'>
                                <Option value="form-report">Report</Option>
                                <Option value="formula">Formula</Option>
                                <Option value="produk">Produk</Option>
                                <Option value="bahan">Bahan Baku</Option>
                                <Option value="form">Form</Option>
                            </Select>
                            <Input onChange={setKodeItemHistory} style={{
                                width: "20%",
                                marginRight: "2%"
                            }} placeholder='Kode Item'></Input>
                            <Button type="primary" style={{ width: "10%",marginRight: "2%" }} onClick={onSearch}>{'Cari'}</Button>
                            <Button type="primary" style={{ width: "10%",marginRight: "2%" }} onClick={()=>{
                                getData()
                                setQuery({})
                            }}>{'Reset'}</Button>
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            rows={10}
                            pagination={true}
                            loading={isLoading}
                            dataSource={list}
                            rowKey='id'
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(History);

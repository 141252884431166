import { Button, Card, Col, Form, Input, Row, message, Table, Select, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchOneFormula } from 'redux/features/formula';
import { fetchAllProduct, fetchOneProduct } from 'redux/features/product';
import jwt_decode from 'jwt-decode'
import { addBatchReport, updateBatchReport } from 'redux/features/batch-report';
import { fetchOneFormReport } from 'redux/features/form-report';
import { fetchOneBatchReport } from 'redux/features/batch-report';
import moment from "moment"
import { MyEditor } from 'components/MyEditor';
import { addHistory } from 'redux/features/history';

const { Option } = Select

const rules = [
    {
        required: true,
        message: 'Wajib Diisi!',
    }
]

export const TAMBAH_BATCH = () => {

    const [form] = Form.useForm()
    const location = useLocation()
    const history = useHistory()
    const [id, setId] = useState(null)
    const [isDraft, setIsDraft] = useState(true)
    const [isLoading, setLoading] = useState(false)
    const [prosedurPenimbangan, setProsedurPenimbangan] = useState(null)
    const [prosedurPengolahan, setProsedurPengolahan] = useState(null)
    const [prosedurPengemasan, setProsedurPengemasan] = useState(null)
    const [persentase, setPersentase] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState({})
    const [filledForm, setFilledForm] = useState(null)
    const [batchReportId, setBatchReportId] = useState(null)
    const [formula, setFormula] = useState("")
    const [tanggal_dibuat, setTanggalDibuat] = useState("")
    const [user, setUser] = useState({})
    const [daftarBahan, setDaftarBahan] = useState([])
    const [daftarProducts, setDaftarProducts] = useState([])
    const [visible, setVisible] = useState(false)
    const dispatch = useDispatch()

    const handleSearch = (newValue) => {
        // fetch(newValue, setData);
    }

    const handleChange = (newValue) => {
        getProductById(newValue)
    }

    const onFinish = async (values) => {
        addItem(values)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const onSubmit = (events) => {
        if (!batchReportId) {
            if(isDraft){
                addItem(events,"DRAFT")
            }else{
                addItem(events,"READY")
            }
        } else {
            if(isDraft){
                updateItem(events,"DRAFT")
            }else{
                updateItem(events,"READY")
            }
        }
    }

    const addItem = async (events,status) => {
        try {
            setLoading(true)
            await dispatch(addBatchReport({
                "name": selectedProduct.name,
                "berat_total": events.berat_total,
                "product": selectedProduct._id,
                "formula": events.formula,
                "created_by": user,
                "status_batch": status,
                "created_at": moment(tanggal_dibuat).unix(),
                "updated_at": moment().unix(),
                "tanggal_dibuat": moment(tanggal_dibuat).format("DD-MM-YYYY"),
                "prosedur_penimbangan": prosedurPenimbangan,
                "prosedur_pengolahan": prosedurPengolahan,
                "prosedur_pengemasan": prosedurPengemasan,
                "status": "On Process",
                "is_done": false,
                "batch_step": "Penimbangan"
            }))
            await dispatch(addHistory({
                "jenis": "batch-report",
                "entity_id": selectedProduct._id,
                "catatan": "Create",
                "name": "Pembuatan Batch Report"
            }))
            message.success("Formula Berhasil Dibuat! Kembali Ke Halaman Depan...")
            setTimeout(() => {
                history.push({
                    pathname: '/app/report'
                })
            }, 1000)
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const updateItem = async (events,status) => {
        try {
            setLoading(true)
            await dispatch(updateBatchReport({
                'id':batchReportId,
                "name": selectedProduct.name,
                "berat_total": events.berat_total,
                "product": selectedProduct._id,
                "formula": events.formula,
                "created_by": user,
                "status_batch": status,
                "created_at": moment(tanggal_dibuat).unix(),
                "updated_at": moment().unix(),
                "tanggal_dibuat": moment(tanggal_dibuat).format("DD-MM-YYYY"),
                "prosedur_penimbangan": prosedurPenimbangan,
                "prosedur_pengolahan": prosedurPengolahan,
                "prosedur_pengemasan": prosedurPengemasan,
                "status": "On Process",
                "is_done": false,
                "batch_step": "Penimbangan"
            }))
            await dispatch(addHistory({
                "jenis": "batch-report",
                "entity_id": selectedProduct._id,
                "catatan": "Create",
                "name": "Pembuatan Batch Report"
            }))
            message.success("Formula Berhasil Dibuat! Kembali Ke Halaman Depan...")
            setTimeout(() => {
                history.push({
                    pathname: '/app/report'
                })
            }, 1000)
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const getUserData = async () => {
        try {
            let token = localStorage.getItem("token")
            let user = jwt_decode(token)
            // const response = await dispatch(getUserProfile(token)).unwrap()
            setUser(user._id)
            form.setFieldsValue({
                created_by: user._id
            })
        } catch (err) {
            console.log(err)
        }
    }

    const getFormulaById = async (id) => {
        try {
            setPersentase(0)
            const item = await dispatch(fetchOneFormula(id)).unwrap()
            setFormula(item)
            form.setFieldsValue({
                formula: item.code
            })
            setDaftarBahan(item.formulaItems)
        } catch (err) {
            console.log(err)
        }
    }

    const getAllProducts = async () => {
        try {
            const products = await dispatch(fetchAllProduct()).unwrap()
            setDaftarProducts(products)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const getProductById = async (id) => {
        try {
            const products = await dispatch(fetchOneProduct(id)).unwrap()
            setSelectedProduct(products[0])
            form.setFieldsValue({
                productCode: products[0].code
            })
            getFormulaById(products[0].formula)
            setProsedurPengemasan(products[0].prosedur_pengemasan)
            setProsedurPenimbangan(products[0].prosedur_penimbangan)
            setProsedurPengolahan(products[0].prosedur_pengolahan)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const getBatchReport = async (batchId) =>{
        const batchReport = await dispatch(fetchOneBatchReport(batchId)).unwrap()
        const data = batchReport[0]
        // console.log(data)
        form.setFieldsValue({
            berat_total: data?.berat_total,
            productCode:data?.product[0]?.code,
            // productCode:data?.product[0]?.code
        })
        const products = await dispatch(fetchOneProduct(data?.product[0]?._id)).unwrap()
        setSelectedProduct(products[0])
        getFormulaById(products[0].formula)
            // form.setFieldsValue({
            //     productCode: products[0].code
            // })
        // console.log(data.product)
        // handleChange(data?.product[0]?._id)
        // setBeratTotal(singleBatch[0]?.berat_total)
        // handleChange(data.product)
        setProsedurPengemasan(data.prosedur_pengemasan || '')
        setProsedurPengolahan(data.prosedur_pengolahan || '')
        setProsedurPenimbangan(data.prosedur_penimbangan || '')
    }

    useEffect(() => {
        const itemId = location.state?.batchId
        if (itemId) {
            setBatchReportId(itemId)
            getBatchReport(itemId)
        }
        getAllProducts()
        getUserData()
    }, [])

    const tableColumns = [
        {
            title: () => <div className="text-center">Kode Bahan</div>,
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.formula?.bahan[0]?.code ? record.formula?.bahan[0]?.code : "Tidak Ada"}</div>)
            },
        },
        {
            title: () => <div className="text-center">Nama Bahan</div>,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.formula?.bahan[0]?.name}</div>)
            },
        },
        {
            title: () => <div className="text-center">Persentase</div>,
            dataIndex: 'percentage',
            key: 'percentage',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{parseFloat(record.percentage).toFixed(5)}</div>)
            },
        }
    ]

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Formula</h2>
                    <p>Tambah atau edit formula sesuaikan dengan kebutuhan anda</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Form
                            form={form}
                            name="basic"
                            onFinish={onSubmit}
                            onFinishFailed={onFinishFailed}
                        >
                            {batchReportId ? (
                                <h3 style={{marginBottom:"10px",marginTop:"5px"}}>{selectedProduct.name}</h3>
                            ) : (
                                <Form.Item
                                label="Pilih Produk"
                                name="product"
                                rules={rules}
                            >
                                <Select
                                    showSearch
                                    value={selectedProduct}
                                    placeholder={"Pilih Produk"}
                                    style={{ width: "100%", color: "black" }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    onChange={handleChange}>
                                    {daftarProducts.map(product => {
                                        return (
                                            <Option value={`${product._id}`} >{`${product.code} - ${product.name}`}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            )}

                            <Form.Item
                                label="Berat Total (gram)"
                                name="berat_total"
                                rules={rules}
                            >
                                <Input placeholder="Gram" />
                            </Form.Item>

                            <Form.Item
                                label="Kode Produk"
                                name="productCode"
                                rules={rules}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label="Kode Formula"
                                name="formula"
                                rules={rules}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label="Created By"
                                name="created_by"
                                rules={rules}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label={"Prosedur Penimbangan (Bisa Diubah Per Batch Report)"}
                                name={"prosedur_penimbangan"}
                            >
                                <MyEditor setState={setProsedurPenimbangan} data={prosedurPenimbangan} default_value={prosedurPenimbangan} />
                            </Form.Item>

                            <Form.Item
                                label={"Prosedur Pengolahan (Bisa Diubah Per Batch Report)"}
                                name={"prosedur_pengolahan"}
                            >
                                <MyEditor setState={setProsedurPengolahan} data={prosedurPengolahan} default_value={prosedurPengolahan} />
                            </Form.Item>

                            <Form.Item
                                label={"Prosedur Pengemasan (Bisa Diubah Per Batch Report)"}
                                name={"prosedur_pengemasan"}
                            >
                                <MyEditor setState={setProsedurPengemasan} data={prosedurPengemasan} default_value={prosedurPengemasan} />
                            </Form.Item>

                            <>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <h2>Template Pembuatan Form Penimbangan</h2>
                                        <p>Semua bahan di produk dan formula ini</p>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <Card>
                                            <Table
                                                className="no-border-last"
                                                columns={tableColumns}
                                                loading={isLoading}
                                                dataSource={daftarBahan}
                                                rowKey='id'
                                            />
                                        </Card>
                                    </Col>
                                </Row>

                                <Form.Item
                                label="Tanggal Dibuat"
                                name="tanggal_dibuat"
                                rules={rules}
                            >
                                <DatePicker onChange={(value) => {
                                    setTanggalDibuat(value)
                                }} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                                label="Status"
                                name="status"
                                required
                            >
                                <Select defaultValue={"DRAFT"} style={{ color: "black" }} onChange={(value) => {
                                    setIsDraft(value === "DRAFT")
                                }} >
                                    <Option value="DRAFT" >DRAFT</Option>
                                    <Option value="ON REVIEW" >SUBMIT</Option>
                                </Select>
                            </Form.Item>

                                {isDraft ? (
                                    <Row gutter={24}>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Button type="primary" style={{ border: "0px", color: "white", background: "orange" }} loading={isLoading} htmlType="submit" onClick={() => {
                                                setVisible(true)
                                            }} block>
                                                Simpan Sebagai Draft
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row gutter={24}>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Button type="primary" style={{ border: "0px", color: "white" }} loading={isLoading} htmlType="submit" onClick={() => {
                                                setVisible(true)
                                            }} block>
                                                Buat Batch Report
                                            </Button>
                                        </Col>
                                    </Row>

                                )}

                            </>

                            {/* <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
                                    {id ? "Update" : "Tambah"}
                                </Button>
                            </Form.Item> */}
                        </Form>
                    </Card>
                </Col>
            </Row>
            {/* <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px", color: "white", marginTop: "10px" }} htmlType="submit" onClick={() => {
                        history.push("/app/report")
                    }} block>
                        Kembali Ke Halaman Utama
                    </Button>
                </Col>
            </Row> */}
        </>
    )
}

export default TAMBAH_BATCH
import { Button, Card, Col, DatePicker, Input, Row, Table, message } from 'antd';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllFormula, searchFormula } from 'redux/features/formula';


export const Formula = () => {
    const history = useHistory()
    const [isLoading] = useState(false)
    const [list, setList] = useState([])
    const dispatch = useDispatch()

    const getData = async () => {
        try {
            const response = await dispatch(fetchAllFormula()).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const searchItem = async (events) => {
        try {
            const response = await dispatch(searchFormula(events.target.value)).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: () => <div className="text-center">Kode Formula</div>,
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.code}</div>)
            },
        },
        {
            title: () => <div className="text-center">Gramasi</div>,
            dataIndex: 'gramasi',
            key: 'gramasi',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record?.gramasi}</div>)
            },
        },
        {
            title: () => <div className="text-center">Tanggal Formula</div>,
            dataIndex: 'tanggal_formula',
            key: 'tanggal_formula',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record?.tanggal_formula}</div>)
            },
        },
        {
            title: () => <div className="text-center">Jumlah Bahan</div>,
            dataIndex: 'formulaItems',
            key: 'formulaItems',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.formulaItems?.length}</div>)
            },
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'detail',
            render: (_, record) => {
                return (
                    <Button type="primary" style={{ border: "0px", width: "100%" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-formula',
                            state: {
                                id: record._id
                            }
                        })
                    }} block>
                        Detail & Tambah Bahan
                    </Button>
                )
            },
        }
    ];

    const onSearch = async (event) => {
        // setPerihal(event.target.value)
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Semua Formula</h2>
                    <p>Semua formula yang telah masuk dan dibutuhkan untuk produk</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <Input
                                placeholder="Cari Berdasarkan Nama"
                                onChange={searchItem}
                                style={{
                                    width: "100%"
                                }}
                            />
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            loading={isLoading}
                            dataSource={list}
                            rowKey='id'
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-formula'
                        })
                    }} block>
                        Tambah
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(Formula);

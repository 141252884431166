import { Col, Row, message } from 'antd';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import React, { useEffect, useState, useCallback } from "react";
import { fetchAllBatchReportCount } from 'redux/features/batch-report';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BatchReport from '../batch-report';

export const Report = () => {

  const dispatch = useDispatch()
  const [count,setCount] = useState({
    myBatch:0,
    totalActiveBatch:0,
    totalBatch:0,
    penimbangan:0,
    pengolahan:0,
    pengemasan:0
  })

    const getData = async () => {
        try {
            const reportData = await dispatch(fetchAllBatchReportCount()).unwrap()
            setCount(reportData[0])
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Batch Report System</h2>
          <p>Jika menemukan bug ataupun error pada aplikasi ini bisa langsung <a target='_blank' href='https://wa.me/6285899731884?text=Hi min, ada error atau bug di aplikasi surat surabah'>klik sini </a></p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
        <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Semua Batch Report`}
                  value={count?.totalBatch || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Batch Report Aktif`}
                  value={count?.totalActiveBatch || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Batch Report Selesai`}
                  value={count?.totalBatch - count?.totalActiveBatch || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Batch Report Anda`}
                  value={count?.myBatch || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Penimbangan`}
                  value={count?.penimbangan || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Pengolahan`}
                  value={count?.pengolahan || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Pengemasan`}
                  value={count?.pengemasan || 0}
                />
              </Col>
            </Row>
        </Col>
      </Row>
      <BatchReport noTitle={true} ></BatchReport>
      {/* <br/>
      <FormReport noTitle={true} ></FormReport> */}
    </>
  )
}


export default withRouter(Report);

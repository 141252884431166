import { Button, Card, Col, Form, Input, Modal, Row, Select, message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addHistory } from 'redux/features/history';
import { useHistory, useLocation } from 'react-router-dom';
import { addFormItem, fetchOneByFormId, updateFormItem, fetchOneFormItem } from 'redux/features/form-item';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const rules = [
    {
        required: true,
        message: 'Wajib Diisi!',
    }
]

const buttonStlye = { border: "0px", width: "50%", margin: "10px" }

const { Option } = Select

export const DETAIL_FORM_ITEM = () => {

    const [form] = Form.useForm()
    const location = useLocation()
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const [id, setId] = useState("")
    const [defaultFieldValue, setDefaultFieldValue] = useState("")
    const [selectedLabel, setSelectedLabel] = useState("")
    const [selectedTipe, setSelectedTipe] = useState("")
    const [formId, setFormId] = useState("")
    const dispatch = useDispatch()

    const onFinish = async (values) => {
        try {
            await dispatch(updateFormItem({
                id:id,
                default_value:defaultFieldValue,
                label:values.label,
                type:values.type
             }))
            message.success("Form Item berhasil diupdate!")
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const getDataById = async (id) => {
        try {
            const item = await dispatch(fetchOneFormItem(id)).unwrap()
            form.setFieldsValue(item[0])
            setSelectedTipe(item[0].type)
            setDefaultFieldValue(item[0].default_value)
        } catch (err) {
            console.log(err)
        }
    }

    const deleteItem = async (id) => {
        message.success("Proses Hapus data...")
        setTimeout(() => {
            history.push({
                pathname: '/app/form'
            })
        }, 1000)
    }

    const renderDefault = (tipe, value) => {
        if (tipe === "Markdown" && value) {
            return (
                <CKEditor
                    editor={ClassicEditor}
                    style={{ height: "50em" }}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                    }}
                    data={value}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setDefaultFieldValue(data)
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />
            )
        } else {
            return (
                <Input type='text' defaultValue={defaultFieldValue} ></Input>
            )
        }
    }

    useEffect(() => {
        const itemId = location.state.state.id
        const formId = location.state.state.formId
        if (itemId) {
            setId(itemId)
            getDataById(itemId)
            setFormId(formId)
        }
    }, [])

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Form Item</h2>
                    <p>Tambah atau edit form sesuaikan dengan kebutuhan anda</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Form form={form}
                            onFinish={onFinish}>
                            <Form.Item
                                name="label"
                                label="Nama"
                            >
                                <Input onChange={(value) => { setSelectedLabel(value.target.value) }} style={{ width: "100%", color: "grey" }}></Input>
                            </Form.Item>
                            <Form.Item
                                name="type"
                                label="Tipe"
                            >
                                <Select onChange={(value) => { setSelectedTipe(value) }} style={{ width: "100%", color: "grey" }}>
                                    <Option value={"Text"}>Text</Option>
                                    <Option value={"Boolean"}>Boolean</Option>
                                    <Option value={"Number"}>Number</Option>
                                    <Option value={"File"}>File</Option>
                                    <Option value={"Markdown"}>Markdown</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="default_value"
                                label="Default Value"
                            >
                                {renderDefault(selectedTipe, defaultFieldValue)}
                                <Form.Item>
                                    <br></br>
                                    <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
                                        Update Data
                                    </Button>
                                </Form.Item>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px", color: "white", marginTop: "10px" }} htmlType="submit" onClick={() => {
                        // setVisible(true)
                        history.push({
                            pathname: '/app/detail-form',
                            state: {
                                id: formId
                            }
                        })
                    }} block>
                        Kembali Ke Form
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default DETAIL_FORM_ITEM
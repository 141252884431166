
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React from 'react';
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const MyEditor = (props)=>{
    function uploadAdapter(loader) {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
              var formData = new FormData();
              loader.file.then((file) => {
                formData.append("file", file);
                axios
                  .post("https://storage-adev.gawegawe.id/upload", formData)
                  .then((res) => res.json())
                  .then((res) => {
                    console.log(res)
                    resolve({
                      default: `/${res.filename}`,
                    });
                  })
                  .catch((err) => {
                    console.log(err)
                    reject(err);
                  });
              });
            });
          },
        };
      }
      function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
      }
    return (
        <CKEditor
            editor={ClassicEditor}
            config={{
                extraPlugins: [uploadPlugin],
              }}
            data={props.data ? props.data : ""}
            style={{ height: "150em" }}
            onReady={editor => {
                // console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
                const data = editor.getData() || "";
                props.setState(data)
            }}
            onBlur={(event, editor) => {
                console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
                console.log('Focus.', editor);
            }}
        />
    )
}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllFormItem = createAsyncThunk(
	'Form/fetchAllFormItem',
	async (_, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.FORM_ITEM)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchOneFormItem = createAsyncThunk(
	'Form/fetchOneFormItem',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.FORM_ITEM}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchOneByFormId = createAsyncThunk(
	'Form/fetchOneByFormId',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.FORM_ITEM}/get-by-form-id/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const addFormItem = createAsyncThunk(
	'Form/addFormItem',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('post', URLS.FORM_ITEM, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const addField = createAsyncThunk(
	'Field/addField',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', `${URLS.FORM_ITEM}/add-field`, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const removeField = createAsyncThunk(
	'Field/removeField',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', `${URLS.FORM_ITEM}/remove-field`, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const updateFormItem = createAsyncThunk(
	'Form/updateFormItem',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', URLS.FORM_ITEM, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const deleteFormItem = createAsyncThunk(
	'Form/deleteFormItem',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('delete', `${URLS.FORM_ITEM}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const initialState = {
	loading: {
		query: false,
		mutation: false
	},
	filter: {
		q: ''
	},
	list: [],
	selected: {},
	selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
	state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const FormSlice = createSlice({
	name: 'Form',
	initialState,
	reducers: {
		setAppliedSearchText: (state, action) => {
			state.filter.q = action.payload
		},
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAllFormItem.pending, startLoadingQuery)
			.addCase(fetchAllFormItem.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllFormItem.rejected, stopLoadingQuery)

		builder
			.addCase(fetchOneFormItem.pending, startLoadingQuery)
			.addCase(fetchOneFormItem.rejected, stopLoadingQuery)
			.addCase(fetchOneFormItem.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(addFormItem.pending, startLoadingQuery)
			.addCase(addFormItem.rejected, stopLoadingQuery)
			.addCase(addFormItem.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(updateFormItem.pending, startLoadingQuery)
			.addCase(updateFormItem.rejected, stopLoadingQuery)
			.addCase(updateFormItem.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(deleteFormItem.pending, startLoadingMutation)
			.addCase(deleteFormItem.fulfilled, stopLoadingMutation)
			.addCase(deleteFormItem.rejected, stopLoadingMutation)
	}
});


export const { setSelectedRows, setAppliedSearchText } = FormSlice.actions;

export default FormSlice.reducer;
export const roles = ["admin",
"user",
"warehouse_manager",
"production_development_manager",
"quality_regulatory_manager",
"rd_staff",
"chief_rd",
"rd_spv",
"quality_control_spv",
"chief_qc",
"in_process_control_staff",
"chief_produksi",
"operator_penimbangan" 
,"operator_pengolahan",
"operator_extruder",
"operator_cutting",
"chief_produksi_pengemasan",
"operator_pengemasan",
"qa_spv",
"qa_staff"]
import { Button, Card, Col, Form, Input, Collapse, Row, message, Table, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { fetchOneFormula } from 'redux/features/formula';
import { useDispatch } from 'react-redux';
import { addHistory } from 'redux/features/history';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { addFormReport, updateFormReport } from 'redux/features/form-report';
import { fetchOneByFormId } from 'redux/features/form-item';
import { fetchOneBatchReport, updateBatchReport } from 'redux/features/batch-report';
import jwt_decode from 'jwt-decode'
import { header_penimbangan, tabel_penimbangan, row, header_penimbangan_filled } from './defaults/penimbangan'
import { fetchOneProduct } from 'redux/features/product';
import moment from "moment"
import { MyEditor } from './MyEditor';

const OPERATOR = ["operator_penimbangan", "operator_pengemasan", "operator_pengolahan",'operator_cutting','operator_extruder']
const APPROVAL_PENIMBANGAN = []
const APPROVAL_PENGOLAHAN = []
const APPROVAL_PENGEMASAN = []

const { Option } = Select

export const DETAIL_FORM = () => {

    const location = useLocation()
    const [batch, setBatch] = useState({})
    const [batchStatus, setBatchStatus] = useState(false)
    const [isDraft, setIsDraft] = useState("DRAFT")
    const [prosedurVisible, setProsedurVisible] = useState(false)
    const [singleForm, setForm] = useState({})
    const [daftarBahan, setDaftarBahan] = useState([])
    const [beratTotal, setBeratTotal] = useState(null)
    const [defaultValue, setDefaultValue] = useState(false)
    const [persentase, setPersentase] = useState(0)
    const [tipeForm, setTipeForm] = useState("Penimbangan")
    const [tumpukDenganProsedur, setTumpukDenganProsedur] = useState(null)
    const [formula, setFormula] = useState("")
    const [singleFormReport, setSingleFormReport] = useState(null)
    const [filledForm, setFilledForm] = useState(null)
    const [submitData, setSubmitData] = useState({})
    const [prosedurPenimbangan, setProsedurPenimbangan] = useState('')
    const [prosedurPengolahan, setProsedurPengolahan] = useState('')
    const [prosedurPengemasan, setProsedurPengemasan] = useState('')
    const [form] = Form.useForm()
    const history = useHistory()
    const [selectedProduct, setSelectedProduct] = useState({})
    const [fields, setFields] = useState([])
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState()
    const dispatch = useDispatch()

    const onFinish = async (values) => {
        setLoading(true)
        saveForm(values)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const saveForm = async (events) => {
        try {
            if (isDraft === "DRAFT") {
                if (singleFormReport?._id) {
                    updateItem(events, "DRAFT")
                } else {
                    addItem(events, "DRAFT")
                }
            } else {
                if (singleFormReport?._id) {
                    updateItem(events, "ON REVIEW")
                } else {
                    addItem(events, "ON REVIEW")
                }
            }
            setLoading(false)
        }
        catch (err) {
            console.log(err)
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const addItem = async (events, status) => {
        // console.log({
        //     events,
        //     status,
        //     submitData
        // })
        try {
            let token = localStorage.getItem("token")
            let user = jwt_decode(token)
            await dispatch(addFormReport({
                "form": singleForm._id,
                "batchReport": batch._id,
                "data": {
                    events: events,
                    form: submitData
                },
                status: status,
                created_by: user._id
            }))
            await dispatch(updateBatchReport({
                id: batch._id,
                nomor: events.nomor
            }))
            await dispatch(addHistory({
                "jenis": "batch-report",
                "entity_id": batch._id,
                "catatan": "Create",
                "name": `Submit Form ${batch.name}`
            }))
            message.success("Form berhasil dibuat silahkan tunggu hasil review!")
            history.push({
                pathname: '/app/detail-batch-report',
                state: {
                    batchId: batch._id
                }
            })
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const updateItem = async (events, status) => {
        try {
            let token = localStorage.getItem("token")
            let user = jwt_decode(token)
            await dispatch(updateFormReport({
                id: singleFormReport?._id,
                "data": {
                    events: events,
                    form: submitData
                },
                status: status,
                updated_at: moment().unix()
            }))
            await dispatch(updateBatchReport({
                id: batch._id,
                nomor: events.nomor
            }))
            await dispatch(addHistory({
                "jenis": "batch-report",
                "entity_id": batch._id,
                "catatan": "Create",
                "name": `Submit Form ${batch.name}`
            }))
            message.success("Form berhasil dibuat silahkan tunggu hasil review!")
            history.push({
                pathname: '/app/detail-batch-report',
                state: {
                    batchId: batch._id
                }
            })
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const getUserData = async () => {
        try {
            let token = localStorage.getItem("token")
            let user = jwt_decode(token)
            setUser(user._id)
            setRole(user.role)
            form.setFieldsValue({
                created_by: user._id
            })
        } catch (err) {
            console.log(err)
        }
    }

    const approve = async () => {
        try {
            let token = localStorage.getItem("token")
            let user = jwt_decode(token)
            await dispatch(updateFormReport({
                id: singleFormReport._id,
                status: "APPROVED",
                updated_at: moment().unix(),
                approved_by: user._id
            }))
            await dispatch(updateBatchReport({
                id: batch._id,
                status: singleForm.name,
                updated_at: moment().unix(),
                batch_step: singleForm.transisi,
                is_done: singleForm.transisi === "Selesai"
            }))
            await dispatch(addHistory({
                "jenis": "batch-report",
                "entity_id": batch._id,
                "catatan": "Update",
                "name": `Approve Form ${batch.name}`
            }))
            message.success("Berhasil di approve!")
            history.push({
                pathname: '/app/detail-batch-report',
                state: {
                    batchId: batch._id
                }
            })
        } catch (err) {
            message.error("Something is wrong!")
        }
    }

    const revision = async (events) => {
        try {
            let token = localStorage.getItem("token")
            let user = jwt_decode(token)
            await dispatch(updateFormReport({
                id: singleFormReport._id,
                "data": {
                    events: events,
                    form: submitData
                },
                status: "ON REVIEW",
                updated_at: moment().unix()
            }))
            await dispatch(addHistory({
                "jenis": "batch-report",
                "entity_id": batch._id,
                "catatan": "Create",
                "name": `Revision Form ${batch.name}`
            }))
            message.success("Berhasil di revisi!")
            history.push({
                pathname: '/app/detail-batch-report',
                state: {
                    batchId: batch._id
                }
            })
        } catch (err) {
            message.error("Something is wrong!")
        }
    }

    const reject = async () => {
        try {
            await dispatch(updateFormReport({
                id: singleFormReport._id,
                status: "REJECTED",
                updated_at: moment().unix(),
            }))
            await dispatch(addHistory({
                "jenis": "batch-report",
                "entity_id": batch._id,
                "catatan": "Delete",
                "name": `Reject Form ${batch.name}`
            }))
            message.error("Berhasil di reject!")
            history.push({
                pathname: '/app/detail-batch-report',
                state: {
                    batchId: batch._id
                }
            })
        } catch (err) {
            message.error("Something is wrong!")
        }
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const getFormulaIfNeeded = async (id, berat_total, batch, product_id) => {
        try {
            setPersentase(0)
            const item = await dispatch(fetchOneFormula(id)).unwrap()
            const product = await dispatch(fetchOneProduct(product_id)).unwrap()
            setFormula(item)
            setDaftarBahan(item.formulaItems)
            let rows = []
            item.formulaItems.map(item => {
                rows = [...rows, row("", item.formula?.bahan[0]?.code, item.formula?.bahan[0]?.name, numberWithCommas(Number(parseFloat(parseFloat(item.percentage) * parseFloat(berat_total))).toFixed(3)), "", "", "", "")]
            })
            if (tipeForm === "Penimbangan") {
                setDefaultValue(header_penimbangan_filled({
                    nama_produk: batch.name,
                    kode_produk: product[0]?.code,
                    nama_formula: item?.code,
                    tanggal_formula_fix: "",
                    skala_produksi: batch.berat_total,
                    nomor_batch: "",
                    ukuran: "",
                    tangki: "",
                    prioritas: ""
                }, tabel_penimbangan(rows)))
            }
            // if(tipeForm === "Pengolahan"){
            //     setDefaultValue(prosedurPengolahan)
            // }
            // if(tipeForm === "Pengemasan"){
            //     setDefaultValue(prosedurPengemasan)
            // }
        } catch (err) {
            console.log(err)
        }
    }

    const renderProsedur = () => {
        if (prosedurVisible === "Iya") {
            if ((singleForm?.jenis_form === "Pemeriksaan")) {
                return (<>
                    <h4>Prosedur {singleForm?.jenis_form}</h4>
                    <MyEditor config={{toolbar:false,readOnly:true}} setState={setProsedurPenimbangan} data={prosedurPenimbangan || ''} ></MyEditor>
                    <br />
                    <h4>Prosedur {singleForm?.jenis_form}</h4>
                    <MyEditor  config={{toolbar:false,readOnly:true}} setState={setProsedurPengolahan} data={prosedurPengolahan || ''} ></MyEditor>
                    <br />
                    <h4>Prosedur {singleForm?.jenis_form}</h4>
                    <MyEditor  config={{toolbar:false,readOnly:true}} setState={setProsedurPengemasan} data={prosedurPengemasan || ''} ></MyEditor>
                </>)
            }
            if ((singleForm?.jenis_form === "Penimbangan")) {
                return (<>
                    <h4>Prosedur {singleForm?.jenis_form}</h4>
                    <MyEditor config={{toolbar:false,readOnly:true}} setState={setProsedurPenimbangan} data={prosedurPenimbangan || ''} ></MyEditor>
                </>)
            }
            else if ((singleForm?.jenis_form === "Pengolahan")) {
                return (<>
                    <h4>Prosedur {singleForm?.jenis_form}</h4>
                    <MyEditor config={{toolbar:false,readOnly:true}} setState={setProsedurPengolahan} data={prosedurPengolahan || ''} ></MyEditor>
                </>)
            }
            else if ((singleForm?.jenis_form === "Pengemasan")) {
                return (<>
                    <h4>Prosedur {singleForm?.jenis_form}</h4>
                    <MyEditor config={{toolbar:false,readOnly:true}} setState={setProsedurPengemasan} data={prosedurPengemasan || ''} ></MyEditor>
                </>)
            }
        }
    }

    const getProductById = async (id) => {
        try {
            const products = await dispatch(fetchOneProduct(id)).unwrap()
            setSelectedProduct(products[0])
            form.setFieldsValue({
                productCode: products[0].code,
            })
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const getBatchById = async (id) => {
        try {
            const singleBatch = await dispatch(fetchOneBatchReport(id)).unwrap()
            form.setFieldsValue({
                berat_total: singleBatch[0]?.berat_total,
                nomor: singleBatch[0]?.nomor
            })
            setBatchStatus(singleBatch[0]?.status)
            setBeratTotal(singleBatch[0]?.berat_total)
            setProsedurPengemasan(singleBatch[0]?.prosedur_pengemasan || '')
            setProsedurPengolahan(singleBatch[0]?.prosedur_pengolahan || '')
            setProsedurPenimbangan(singleBatch[0]?.prosedur_penimbangan || '')
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const setupFields = async (id) => {
        const fields = await dispatch(fetchOneByFormId(id)).unwrap()
        setFields(fields)
    }

    useEffect(() => {
        const { batch, form, formReports, status } = location.state
        setTipeForm(form.jenis_form)
        setProsedurVisible(form.tampilkan_prosedur)
        setTumpukDenganProsedur(form.tumpuk_dengan_prosedur)
        getFormulaIfNeeded(batch?.product[0]?.formula, batch.berat_total, batch, batch.product[0]._id)
        setupFields(form._id)
        setBatch({ ...batch })
        getBatchById(batch._id)
        setForm(form)
        setSelectedProduct(batch.product[0].name)
        getProductById(batch.product[0]._id)
        getUserData()
        if (status === "Detail" && formReports.length > form.urutan - 1) {
            setFilledForm(formReports[form.urutan - 1].data.form)
            setSingleFormReport(formReports[form?.urutan - 1])
            setSubmitData(formReports[form?.urutan - 1].data.form)
        }
    }, [])

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Form: {location.state.form.name}</h2>
                    {batchStatus === "Form Pelulusan Produk Jadi" ? <h3 style={{color:"green"}}>Selesai</h3> : <h3 style={{color:"red"}}>Belum Selesai</h3>}
                    <p>Form ini mengacu pada produk: {location.state.batch.name}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <h3>{selectedProduct?.name}</h3>
                        <br />
                        <Form
                            form={form}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >

                            <Form.Item
                                label="Kode Produk"
                                name="productCode"
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label="Nomor Batch"
                                name="nomor"
                            >
                                <Input disabled={batch.batch_step !== "Pengolahan"} />
                            </Form.Item>

                            <Form.Item
                                label="Berat Total (Gram)"
                                name="berat_total"
                                disabled
                            >
                                <Input disabled placeholder="200" />
                            </Form.Item>

                            {/* <Form.Item
                                label="Kode Formula"
                                name="formula"
                            >
                                <Input disabled />
                            </Form.Item> */}

                            <Form.Item
                                label="Created By"
                                name="created_by"
                            >
                                <Input disabled />
                            </Form.Item>

                            {/* {singleForm.code === "CPB" && ()} */}

                            {renderProsedur()}

                            <br />

                            {/* {renderForm(singleForm, batch)} */}

                            {fields && fields.map(field => {
                                let { label, type } = field
                                if (type === "Markdown") {
                                    if (tipeForm === 'Penimbangan') {
                                        return (
                                            <Form.Item
                                                label={label}
                                                name={type}
                                            >
                                                <MyEditor product={selectedProduct} type={ClassicEditor} data={filledForm || ''} default_value={tumpukDenganProsedur === "Iya" ? defaultValue : field?.default_value} setState={setSubmitData} />
                                            </Form.Item>
                                        )
                                    }
                                    if (tipeForm === 'Pengolahan') {
                                        return (
                                            <Form.Item
                                                label={label}
                                                name={type}
                                            >
                                                <MyEditor product={selectedProduct} type={ClassicEditor} data={filledForm || ''} default_value={tumpukDenganProsedur === "Iya" ? prosedurPengolahan : field?.default_value} setState={setSubmitData} />
                                            </Form.Item>
                                        )
                                    }
                                    if (tipeForm === 'Pengemasan') {
                                        return (
                                            <Form.Item
                                                label={label}
                                                name={type}
                                            >
                                                <MyEditor product={selectedProduct} type={ClassicEditor} data={filledForm || ''} default_value={tumpukDenganProsedur === "Iya" ? prosedurPengemasan : field?.default_value} setState={setSubmitData} />
                                            </Form.Item>
                                        )
                                    }
                                } else {
                                    return (
                                        <Form.Item
                                            label={label}
                                            name={type}
                                        >
                                            <Input type={type} />
                                        </Form.Item>
                                    )
                                }
                            })}

                            {singleFormReport?.status !== "ON REVIEW" && (
                                <Form.Item
                                    label="Status"
                                    name="status"
                                    required
                                >
                                    <Select defaultValue={"DRAFT"} style={{ color: "black" }} onChange={(value) => {
                                        setIsDraft(value)
                                    }} >
                                        <Option value="DRAFT" >DRAFT</Option>
                                        <Option value="ON REVIEW" >SUBMIT</Option>
                                    </Select>
                                </Form.Item>
                            )}

                            {(location.state.status === "Isi Form" || singleFormReport?.status === "DRAFT") && (<>
                                <Row gutter={24} style={{ marginTop: "10px" }}>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <Button loading={loading} type="primary" style={{ border: "0px", color: "white", background: isDraft === "ON REVIEW" ? "" : "orange" }} htmlType="submit" block>
                                            {isDraft === "ON REVIEW" ? "Submit" : "Save As Draft"}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                            )}

                            {singleFormReport?.status !== "APPROVED" && (
                                <>
                                    {location.state.status !== "Detail" || singleFormReport?.status === "REJECTED" && (
                                        <>
                                            {singleFormReport?.status !== "REJECTED" && batchStatus !== "Form Pelulusan Produk Jadi" ? (
                                                <Row gutter={24} style={{ marginTop: "10px" }}>
                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                        <Button loading={loading} type="primary" style={{ border: "0px", color: "white" }} htmlType="submit" block>
                                                            Submit Form
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row gutter={24} style={{ marginTop: "10px" }}>
                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                        <Button loading={loading} onClick={revision} type="primary" style={{ border: "0px", color: "white" }} block>
                                                            Resubmit Form
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Form>
                        
                        {(!OPERATOR.includes(role) && location.state.status === "Detail") && (
                            <>
                                {singleFormReport?.status !== "APPROVED" && (
                                    <>
                                        {singleFormReport?.status === "ON REVIEW" && (
                                            <>
                                                <Row gutter={24} style={{ marginTop: "10px" }}>
                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                        <Button loading={loading} onClick={approve} type="primary" style={{ border: "0px", color: "white", background: "green" }} htmlType="submit" block>
                                                            Approve
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24} style={{ marginTop: "10px" }}>
                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                        <Button loading={loading} onClick={reject} type="primary" style={{ border: "0px", color: "white", background: "red" }} htmlType="submit" block>
                                                            Reject
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </>
                                )}
                                {singleFormReport?.status === "APPROVED" && singleForm?.editable === "Iya" && batchStatus !== "Form Pelulusan Produk Jadi" && (
                                    <>
                                     <Row gutter={24} style={{ marginTop: "10px" }}>
                                            <Col xs={24} sm={24} md={24} lg={24}>
                                                <Button loading={loading} onClick={revision} type="primary" style={{ border: "0px", color: "white" }} block>
                                                    Resubmit Form
                                                </Button>
                                            </Col>
                                        </Row>
                                </>
                                )}
                            </>
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default DETAIL_FORM
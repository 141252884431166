import React from "react";
import jwt_decode from 'jwt-decode'
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const user = jwt_decode(localStorage.getItem('token'))
      if(user._id){
        return (
          <Component {...props} />
        )
      }else{
        return (
          <Redirect to="/auth" />
        )
      }
    }
    }
  />
);

export default PrivateRoute;
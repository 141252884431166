import { Button, Card, Col, Form, Input, Modal, Row, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addHistory } from 'redux/features/history';
import { addBahan, fetchOneBahan, deleteBahan, updateBahan } from 'redux/features/bahan';

const rules = [
    {
        required: true,
        message: 'Wajib Diisi!',
    }
]

export const DETAIL_BAHAN = () => {

    const [form] = Form.useForm()
    const location = useLocation()
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const [id, setId] = useState("")
    const [visible, setVisible] = useState(false)
    const [code, setCode] = useState('')
    const dispatch = useDispatch()

    const handleDelete = async () => {
        try {
            deleteItem(id)
        } catch (err) {
            console.log(err)
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onFinish = async (values) => {
        if (id) {
            updateItem(values)
        } else {
            addItem(values)
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const addItem = async (values) => {
        try {
            const response = await dispatch(addBahan({ ...values })).unwrap()
            await dispatch(addHistory({
                "jenis": "bahan",
                "entity_id": values.code,
                "catatan": "Create",
                "name": values.name
            }))
            if(response.code){
                message.success("Bahan Berhasil Dibuat! Kembali Ke Halaman Depan...")
                setTimeout(() => {
                    history.push({
                        pathname: '/app/bahan'
                    })
                }, 1000)
            }else{
                message.error("Bahan gagal diinput! Kemungkinan kode bahan sudah ada.")
            }
        }
        catch (err) {
            message.error("Bahan gagal diinput! Kemungkinan kode bahan sudah ada.")
        }
    }

    const updateItem = async (values) => {
        try {
            await dispatch(updateBahan({ ...values, id: id }))
            await dispatch(addHistory({
                "jenis": "bahan",
                "entity_id": values.code,
                "catatan": "Update",
                "name": values.name
            }))
            message.success("Bahan berhasil diupdate!")
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const getDataById = async (id) => {
        try {
            const item = await dispatch(fetchOneBahan(id)).unwrap()
            form.setFieldsValue(item[0])
            setCode(item[0].code)
        } catch (err) {
            console.log(err)
        }
    }

    const deleteItem = async (id) => {
        await dispatch(deleteBahan(id))
        await dispatch(addHistory({
            "jenis": "bahan",
            "entity_id": code,
            "catatan": "Delete",
            "name": "Dihapus"
        }))
        message.success("Proses Hapus data...")
        setTimeout(() => {
            history.push({
                pathname: '/app/bahan'
            })
        }, 1000)
    }

    useEffect(() => {
        const itemId = location.state?.id
        if (itemId) {
            setId(itemId)
            getDataById(itemId)
        }
    }, [])

    return (
        <>
            <Modal
                title="Hapus Data"
                visible={visible}
                onOk={handleDelete}
                onCancel={handleCancel}
            >
                <p>Anda yakin ingin melakukan aksi ini?</p>
            </Modal>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Bahan</h2>
                    <p>Tambah atau edit bahan sesuaikan dengan kebutuhan anda</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Form
                            form={form}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Kode Bahan"
                                name="code"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Nama Bahan"
                                name="name"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Satuan"
                                name="unit"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
                                    {id ? "Update" : "Tambah"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            {id && (
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Button type="danger" style={{ border: "0px", color: "white" }} htmlType="submit" onClick={() => {
                            setVisible(true)
                        }} block>
                            Hapus
                        </Button>
                    </Col>
                </Row>
            )}
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px", color: "white", marginTop: "10px" }} htmlType="submit" onClick={() => {
                        // setVisible(true)
                        history.push("/app/bahan")
                    }} block>
                        Kembali Ke Halaman Utama
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default DETAIL_BAHAN
import { Button, Card, Col, Input, Row, Table } from 'antd';
import moment from 'moment';
import React,{ useState } from "react";
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';


export const Master = () => {
    const [isLoading, setLoading] = useState(false)
    const {list} = useSelector(state => state.master || [])

    const tableColumns = [
        {
            title: 'Tanggal Dibuat',
            dataIndex: 'tanggalBatch',
            render: (_, record) => {
                return (
                    moment(record.tanggalBatch).format("DD-MMM-YY")
                )
            }
        },
        {
            title: 'Nomor Batch',
            dataIndex: 'nomorBatch',
            key: 'nomorBatch',
        },
        {
            title: 'Nama Batch',
            dataIndex: 'nama_batch',
            key: 'nama_batch',
        },
        {
            title: 'Jumlah Form',
            dataIndex: 'jumlah_form',
            key: 'jumlah_form',
        },
        {
            title: 'Jumlah Field',
            dataIndex: 'jumlah_field',
            key: 'jumlah_field',
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'detail',
            render: (_, record) => {
                {
                    if (record.jenisSurat === "MASUK") {
                        return (
                            <h5 style={{ color: "blue", textAlign: "center" }}>MASUK</h5>
                        )
                    } else {
                        return (
                            <h5 style={{ color: "green", textAlign: "center" }}>KELUAR</h5>
                        )
                    }
                }
            },
        }
    ];

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Semua Master Data</h2>
                    <p>Semua batch yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <Input
                                placeholder="Cari Berdasarkan Nama"
                                onChange={()=>{}}
                                style={{
                                    width: "80%",
                                    marginRight: "2%"
                                }}
                            />
                            <Button type="primary" style={{ width: "20%" }} onClick={() => {}}>Cari</Button>
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            loading={isLoading}
                            dataSource={list}
                            rowKey='id'
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default withRouter(Master);

import { Button, Card, Col, Form, Input, Modal, Row, Select, message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { updateHistory, fetchOneHistory } from 'redux/features/history';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const rules = [
    {
        required: true,
        message: 'Wajib Diisi!',
    }
]

const { Option } = Select

export const DETAIL_HISTORY = () => {

    const [form] = Form.useForm()
    const location = useLocation()
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const [id, setId] = useState("")
    const [defaultFieldValue, setDefaultFieldValue] = useState("")
    const [selectedLabel, setSelectedLabel] = useState("")
    const [selectedTipe, setSelectedTipe] = useState("")
    const dispatch = useDispatch()

    const onFinish = async (values) => {
        try {
            await dispatch(updateHistory({
                id:id,
                default_value:defaultFieldValue,
                label:values.label,
                type:values.type
             }))
            message.success("Form Item berhasil diupdate!")
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const getDataById = async (id) => {
        try {
            const item = await dispatch(fetchOneHistory(id)).unwrap()
            form.setFieldsValue(item[0])
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        const itemId = location.state.id
        if (itemId) {
            setId(itemId)
            getDataById(itemId)
        }
    }, [])

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail History</h2>
                    <p>Tambah atau edit form sesuaikan dengan kebutuhan anda</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Form form={form}
                            onFinish={onFinish}>
                            <Form.Item
                                name="catatan"
                                label="Keterangan"
                            >
                                <Input disabled ></Input>
                            </Form.Item>
                            <Form.Item
                                name="writtenBy"
                                label="Oleh"
                            >
                                <Input disabled ></Input>
                            </Form.Item>
                            <Form.Item
                                name="date"
                                label="Waktu"
                            >
                                <Input disabled ></Input>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px", color: "white", marginTop: "10px" }} htmlType="submit" onClick={() => {
                        // setVisible(true)
                        history.push({
                            pathname: '/app/history'
                        })
                    }} block>
                        Kembali Ke History
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default DETAIL_HISTORY
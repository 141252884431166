import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllFormReport = createAsyncThunk(
	'FormReport/fetchAllFormReport',
	async (_, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.FORM_REPORT)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const searchFormReport = createAsyncThunk(
	'FormReport/searchFormReport',
	async (title, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.FORM_REPORT}/search?name=${title}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchOneFormReport = createAsyncThunk(
	'FormReport/fetchOneFormReport',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.FORM_REPORT}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchAllByBatchId = createAsyncThunk(
	'FormReport/fetchAllByBatchId',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.FORM_REPORT}/find-by-batch-id/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const addFormReport = createAsyncThunk(
	'FormReport/addFormReport',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('post', URLS.FORM_REPORT, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const updateFormReport = createAsyncThunk(
	'FormReport/updateFormReport',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', URLS.FORM_REPORT, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const deleteFormReport = createAsyncThunk(
	'FormReport/deleteFormReport',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('delete', `${URLS.FORM_REPORT}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const initialState = {
	loading: {
		query: false,
		mutation: false
	},
	filter: {
		q: ''
	},
	list: [],
	selected: {},
	selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
	state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const FormReportSlice = createSlice({
	name: 'FormReport',
	initialState,
	reducers: {
		setAppliedSearchText: (state, action) => {
			state.filter.q = action.payload
		},
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAllFormReport.pending, startLoadingQuery)
			.addCase(fetchAllFormReport.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllFormReport.rejected, stopLoadingQuery)

		builder
			.addCase(fetchOneFormReport.pending, startLoadingQuery)
			.addCase(fetchOneFormReport.rejected, stopLoadingQuery)
			.addCase(fetchOneFormReport.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(updateFormReport.pending, startLoadingQuery)
			.addCase(updateFormReport.rejected, stopLoadingQuery)
			.addCase(updateFormReport.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(searchFormReport.pending, startLoadingQuery)
			.addCase(searchFormReport.rejected, stopLoadingQuery)
			.addCase(searchFormReport.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(deleteFormReport.pending, startLoadingMutation)
			.addCase(deleteFormReport.fulfilled, stopLoadingMutation)
			.addCase(deleteFormReport.rejected, stopLoadingMutation)
	}
});


export const { setSelectedRows, setAppliedSearchText } = FormReportSlice.actions;

export default FormReportSlice.reducer;
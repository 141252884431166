import { Col, Row, message } from 'antd';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import React, { useEffect, useState, useCallback } from "react";
import { fetchAllBatchReportCount } from 'redux/features/batch-report';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Batch } from '../batch';

export const AnnualStatisticData = [
  {
    title: 'Total Surat',
    value: 'Loading...',
  },
  {
    title: 'Surat Masuk',
    value: 'Loading...',
  },
  {
    title: 'Surat Keluar',
    value: 'Loading...',
  }
]

export const DefaultDashboard = () => {

  const dispatch = useDispatch()
  const [reports,setReports] = useState()

  const getData = useCallback(async () => {
    try {
      const reports = await dispatch(fetchAllBatchReportCount()).unwrap()
      console.log(reports)
      setReports(reports)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>Jika menemukan bug ataupun error pada aplikasi ini bisa langsung <a target='_blank' href='https://wa.me/6285899731884?text=Hi min, ada error atau bug di aplikasi surat surabah'>klik sini </a></p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
        <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={1}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Batch Report Aktif`}
                  value={reports?.totalBatch || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Batch Report Selesai`}
                  value={reports?.totalActiveBatch || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Batch Report Hari Ini`}
                  value={reports?.suratMasuk || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Penimbangan`}
                  value={reports?.penimbangan || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Pengolahan`}
                  value={reports?.pengolahan || 0}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Pengemasan`}
                  value={reports?.pengemasan || 0}
                />
              </Col>
            </Row>
        </Col>
      </Row>
      <Batch noTitle={true} ></Batch>
    </>
  )
}


export default withRouter(DefaultDashboard);

import { Button, Card, Col, DatePicker, Input, Row, Table, message } from 'antd';
import React,{ useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllBatch } from 'redux/features/batch';


export const Batch = () => {
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [startDateState, setStartDate] = useState()
    const [endDateState, setEndDate] = useState()
    const [perihal, setPerihal] = useState()
    const [jenisSurat, setJenisSurat] = useState()

    const {
        list,
        filter: { q: searchTerm },
        loading: {
            query: loadingQuery,
            mutation: loadingMutation
        }
    } = useSelector(state => state.batch || [])

    const getData = useCallback(async () => {
        try {
            await dispatch(fetchAllBatch()).unwrap()
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: () => <div className="text-center">Kode Batch</div>,
            dataIndex: 'nomor',
            key: 'nomor',
            render: (_, record) => {
                return(<div style={{textAlign:"center"}}>{record.nomor}</div>)
            },
        },
        {
            title: () => <div className="text-center">Nama Batch</div>,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return(<div style={{textAlign:"center"}}>{record.name}</div>)
            },
        },
        {
            title:  () => <div className="text-center">Jumlah Form</div>,
            dataIndex: 'jumlah_form',
            key: 'jumlah_form',
            render: (_, record) => {
                return(<div style={{textAlign:"center"}}>{record.forms?.length}</div>)
            },
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'detail',
            render: (_, record) => {
                return(
                    <Button type="primary" style={{ border: "0px",width:"100%" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-batch',
                            state:{
                                id:record._id
                            }
                        })
                    }} block>
                        Detail 
                    </Button>
                )
            },
        }
    ];

    const onSearch = async (event) => {
        // setPerihal(event.target.value)
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Semua Batch</h2>
                    <p>Semua batch yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <Input
                                placeholder="Cari Berdasarkan Nama"
                                onChange={onSearch}
                                style={{
                                    width: "90%",
                                    marginRight: "2%"
                                }}
                            />

                            <Button type="primary" style={{ width: "10%" }} onClick={() => { }}>Cari</Button>
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            rows={5}
                            loading={isLoading}
                            dataSource={list}
                            rowKey='id'
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-batch'
                        })
                    }} block>
                        Tambah 
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(Batch);

import { Button, Card, Col, Form, Input, Modal, Row, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct, fetchOneProduct, deleteProduct, updateProduct } from 'redux/features/product';
import { fetchAllFormula,updateFormula } from 'redux/features/formula';
import { MyEditor } from 'components/MyEditor';
import { addHistory } from 'redux/features/history';

const rules = [
    {
        required: true,
        message: 'Wajib Diisi!',
    }
]

const { Option } = Select

export const DETAIL_PRODUCT = () => {

    const [form] = Form.useForm()
    const location = useLocation()
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const [formulas, setFormulas] = useState([])
    const [id, setId] = useState("")
    const [productName, setProductName] = useState(null)
    const [productCode, setProductCode] = useState(null)
    const [prosedurPenimbangan, setProsedurPenimbangan] = useState(null)
    const [prosedurPengolahan, setProsedurPengolahan] = useState(null)
    const [prosedurPengemasan, setProsedurPengemasan] = useState(null)
    const [visible, setVisible] = useState(false)
    const [selectedFormula, setSelectedFormula] = useState("");
    const dispatch = useDispatch()

    const handleDelete = async () => {
        try {
            deleteItem(id)
        } catch (err) {
            console.log(err)
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onFinish = async (values) => {
        if (id) {
            updateItem(values)
        } else {
            addItem(values)
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const handleSearch = (newValue) => {
        // fetch(newValue, setData);
    };
    const handleChange = (newValue) => {
        setSelectedFormula(newValue)
    };

    const addItem = async (values) => {
        try {
            await dispatch(addProduct({
                ...values,
                prosedur_pengemasan: prosedurPengemasan,
                prosedur_pengolahan: prosedurPengolahan,
                prosedur_penimbangan: prosedurPenimbangan
            }))
            message.success("Product Berhasil Dibuat! Kembali Ke Halaman Depan...")
            setTimeout(() => {
                history.push({
                    pathname: '/app/produk'
                })
            }, 1000)
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const updateItem = async (values) => {
        try {
            await dispatch(updateProduct({
                ...values, id: id, formula: selectedFormula, 
                prosedur_pengemasan: prosedurPengemasan,
                prosedur_pengolahan: prosedurPengolahan,
                prosedur_penimbangan: prosedurPenimbangan
            }))
            await dispatch(updateFormula({
                id:selectedFormula,
                productCode:productCode
            }))
            message.success("Produk berhasil diupdate!")
            setTimeout(() => {
                history.push({
                    pathname: '/app/produk'
                })
            }, 1000)
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const getFormulas = async () => {
        const formulaCodes = await dispatch(fetchAllFormula()).unwrap()
        setFormulas(formulaCodes)
    }

    const getDataById = async (id) => {
        try {
            await dispatch(fetchAllFormula())
            const item = await dispatch(fetchOneProduct(id)).unwrap()
            setSelectedFormula(item[0].formula)
            form.setFieldsValue(item[0])
            setProductName(item[0].name)
            setProductCode(item[0].code)
            setProsedurPenimbangan(item[0].prosedur_penimbangan)
            setProsedurPengolahan(item[0].prosedur_pengolahan)
            setProsedurPengemasan(item[0].prosedur_pengemasan)
        } catch (err) {
            console.log(err)
        }
    }

    const deleteItem = async (id) => {
        await dispatch(deleteProduct(id))
        message.success("Proses Hapus data...")
        setTimeout(() => {
            history.push({
                pathname: '/app/produk'
            })
        }, 1000)
    }

    useEffect(() => {
        getFormulas()
        const itemId = location.state?.id
        if (itemId) {
            setId(itemId)
            getDataById(itemId)
        }
    }, [dispatch])

    return (
        <>
            <Modal
                title="Hapus Data"
                visible={visible}
                onOk={handleDelete}
                onCancel={handleCancel}
            >
                <p>Anda yakin ingin melakukan aksi ini?</p>
            </Modal>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Product</h2>
                    <p>Tambah atau edit product sesuaikan dengan kebutuhan anda</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Form
                            form={form}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Kode Product"
                                name="code"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Nama Produk"
                                name="name"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            {id && (
                                  <Form.Item
                                  label="Kode Formula"
                                  name="formula"
                                  rules={rules}
                              >
                                  <Select
                                      showSearch
                                      value={selectedFormula}
                                      placeholder={"Kode Formula"}
                                      style={{ width: "100%", color: "black" }}
                                      defaultActiveFirstOption={false}
                                      showArrow={false}
                                      filterOption={false}
                                      onSearch={handleSearch}
                                      onChange={handleChange}>
                                      {formulas.map(formula => {
                                          return (
                                              <Option value={`${formula._id}`} >{`${formula._id} - ${formula.code}`}</Option>
                                          )
                                      })}
                                  </Select>
                              </Form.Item>
                            )}

                            <Form.Item
                                label="Prosedur Penimbangan"
                                name="prosedur_penimbangan"
                            >
                                <MyEditor setState={setProsedurPenimbangan} data={prosedurPenimbangan}></MyEditor>
                            </Form.Item>

                            <Form.Item
                                label="Prosedur Pengolahan"
                                name="prosedur_pengelohan"
                            >
                                <MyEditor setState={setProsedurPengolahan} data={prosedurPengolahan}></MyEditor>
                            </Form.Item>

                            <Form.Item
                                label="Prosedur Pengemasan"
                                name="prosedur_pengemasan"
                            >
                                <MyEditor setState={setProsedurPengemasan} data={prosedurPengemasan} ></MyEditor>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
                                    {id ? "Update" : "Tambah"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            {id && (
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Button type="danger" style={{ border: "0px", color: "white" }} htmlType="submit" onClick={() => {
                            setVisible(true)
                        }} block>
                            Hapus
                        </Button>
                    </Col>
                </Row>
            )}
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px", color: "white", marginTop: "10px" }} htmlType="submit" onClick={() => {
                        history.push("/app/produk")
                    }} block>
                        Kembali Ke Halaman Utama
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default DETAIL_PRODUCT
import { Button, Card, Col, DatePicker, Input, Row, Table, message } from 'antd';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllProduct, searchProduct } from 'redux/features/product';

export const Product = () => {
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const dispatch = useDispatch()

    const getData = async () => {
        try {
            const response = await dispatch(fetchAllProduct()).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const searchItem = async (events) => {
        try {
            const response = await dispatch(searchProduct(events.target.value)).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: () => <div className="text-center">Kode Produk</div>,
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.code}</div>)
            },
        },
        {
            title: () => <div className="text-center">Nama Produk</div>,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.name}</div>)
            },
        },
        {
            title: () => <div className="text-center">Formula</div>,
            dataIndex: 'formula',
            key: 'formula',
            render: (_, record) => {
                return record.formula?.code ? (<div style={{ textAlign: "center" }}>{record.formula?.code}</div>) : (<div style={{ textAlign: "center", color: "red" }}>Belum Ada</div>)
            },
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'detail',
            render: (_, record) => {
                return (
                    <Button type="primary" style={{ border: "0px", width: "100%" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-product',
                            state: {
                                id: record._id
                            }
                        })
                    }} block>
                        Atur Formula
                    </Button>
                )
            },
        }
    ];

    const onSearch = async (event) => {
        // setPerihal(event.target.value)
        // searchItem(event.target.value)
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Semua Produk</h2>
                    <p>Semua Produk yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <Input
                                placeholder="Cari Berdasarkan Nama"
                                name={'search'}
                                onChange={searchItem}
                                style={{
                                    width: "100%",
                                    // marginRight: "2%"
                                }}
                            />

                            {/* <Button type="primary" style={{ width: "10%" }} onClick={() => { }}>Cari</Button> */}
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            loading={isLoading}
                            dataSource={list}
                            rowKey='id'
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-product'
                        })
                    }} block>
                        Tambah
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(Product);

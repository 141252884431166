import { Button, Card, Col, DatePicker, Input, Row, Table, message } from 'antd';
import React,{ useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllBahan,searchBahan } from 'redux/features/bahan';


export const Bahan = () => {
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const dispatch = useDispatch()

    const getData = async () => {
        try {
           const response = await dispatch(fetchAllBahan()).unwrap()
           setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const searchItem = async (events) => {
        try {
            const response = await dispatch(searchBahan(events.target.value)).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: () => <div className="text-center">Kode Bahan</div>,
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => {
                return(<div style={{textAlign:"center"}}>{record.code}</div>)
            },
        },
        {
            title: () => <div className="text-center">Nama Bahan</div>,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return(<div style={{textAlign:"center"}}>{record.name}</div>)
            },
        },
        {
            title: () => <div className="text-center">Satuan Bahan</div>,
            dataIndex: 'unit',
            key: 'unit',
            render: (_, record) => {
                return(<div style={{textAlign:"center"}}>{record.unit}</div>)
            },
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'detail',
            render: (_, record) => {
                return(
                    <Button type="primary" style={{ border: "0px",width:"100%" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-bahan',
                            state:{
                                id:record._id
                            }
                        })
                    }} block>
                        Detail 
                    </Button>
                )
            },
        }
    ];

    const onSearch = async (event) => {
        // setPerihal(event.target.value)
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Semua Bahan</h2>
                    <p>Semua Bahan yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <Input
                                placeholder="Cari Berdasarkan Nama"
                                onChange={searchItem}
                                style={{
                                    width: "100%"
                                }}
                            />
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            loading={isLoading}
                            dataSource={list}
                            rowKey='id'
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-bahan'
                        })
                    }} block>
                        Tambah 
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default withRouter(Bahan);

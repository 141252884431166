import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllBatch = createAsyncThunk(
	'Batch/fetchAllBatch',
	async (_, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.BATCH)
			return response.data
		} catch(error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchOneBatch = createAsyncThunk(
	'Batch/fetchOneBatch',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.BATCH}/${id}`)
			return response.data
		} catch(error) {
			return rejectWithValue(error)
		}
	}
)

export const addBatch = createAsyncThunk(
    'Batch/addBatch',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.BATCH, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateBatch = createAsyncThunk(
    'Batch/updateBatch',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', URLS.BATCH, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteBatch = createAsyncThunk(
	'Batch/deleteBatch',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('delete', `${URLS.BATCH}/${id}`)
			return response.data
		} catch(error) {
			return rejectWithValue(error)
		}
	}
)

export const addField = createAsyncThunk(
	'BatchField/addBatchField',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', `${URLS.BATCH}/add-field`, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const removeField = createAsyncThunk(
	'BatchField/removeBatchField',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', `${URLS.BATCH}/remove-field`, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const initialState = {
  loading: {
	  query: false,
	  mutation: false
  },
  filter: {
	  q: ''
  },
  list: [],
  selected: {},
  selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
	state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const BatchSlice = createSlice({
	name: 'Batch',
	initialState,
	reducers: {
		setAppliedSearchText: (state, action) => {
			state.filter.q = action.payload
		},
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAllBatch.pending, startLoadingQuery)
			.addCase(fetchAllBatch.fulfilled, (state, action) => {
				state.list = action.payload 
				state.loading.query = false
			})
			.addCase(fetchAllBatch.rejected, stopLoadingQuery)

		builder
			.addCase(fetchOneBatch.pending, startLoadingQuery)
			.addCase(fetchOneBatch.rejected, stopLoadingQuery)
			.addCase(fetchOneBatch.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

			builder
			.addCase(removeField.pending, startLoadingQuery)
			.addCase(removeField.rejected, stopLoadingQuery)
			.addCase(removeField.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

			builder
			.addCase(updateBatch.pending, startLoadingQuery)
			.addCase(updateBatch.rejected, stopLoadingQuery)
			.addCase(updateBatch.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

			builder
			.addCase(addField.pending, startLoadingQuery)
			.addCase(addField.rejected, stopLoadingQuery)
			.addCase(addField.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(deleteBatch.pending, startLoadingMutation)
			.addCase(deleteBatch.fulfilled, stopLoadingMutation)
			.addCase(deleteBatch.rejected, stopLoadingMutation)
	}
});


export const { setSelectedRows, setAppliedSearchText } = BatchSlice.actions;

export default BatchSlice.reducer;
import { configureStore } from '@reduxjs/toolkit'
import themeReducer from 'redux/features/theme'
import authReducer from 'redux/features/auth'
import suratReducer from 'redux/features/surat'
import penggunaReducer from 'redux/features/pengguna'
import batchReducer from 'redux/features/batch'
import productReducer from 'redux/features/product'
import formulaReducer from 'redux/features/formula'
import formReducer from 'redux/features/form'
import formItemReducer from 'redux/features/form-item'
import bahanReducer from 'redux/features/bahan'
import historyReducer from 'redux/features/history'
import formulaItemReducer from 'redux/features/formula-item'
import BatchReportReducer from 'redux/features/batch-report'
import FormReportReducer from 'redux/features/form-report'

const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    surat:suratReducer,
    batch:batchReducer,
    pengguna:penggunaReducer,
    product:productReducer,
    history:historyReducer,
    formula:formulaReducer,
    form:formReducer,
    formItem:formItemReducer,
    bahan:bahanReducer,
    formulaItem:formulaItemReducer,
    batchReport:BatchReportReducer,
    formReport:FormReportReducer
  }
});

export default store;


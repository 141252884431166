import { Button, Card, Col, Form, Input, Modal, Row, Select, message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { addForm, deleteForm, fetchOneForm, updateForm, addField, removeField } from 'redux/features/form';
import { addHistory } from 'redux/features/history';
import { addFormItem, fetchOneByFormId, deleteFormItem, fetchOneFormItem } from 'redux/features/form-item';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const rules = [
    {
        required: true,
        message: 'Wajib Diisi!',
    }
]

const buttonStlye = { border: "0px", width: "50%", margin: "10px" }

const { Option } = Select

export const DETAIL_BAHAN = () => {

    const [form] = Form.useForm()
    const location = useLocation()
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const [editable, setEditable] = useState(false)
    const [tampilkanProsedur, setTampilkanProsedur] = useState("")
    const [tambahFieldVisible, setTambahFieldVisible] = useState(false)
    const [editFieldVisible, setEditFieldVisible] = useState(false)
    const [id, setId] = useState("")
    const [fields, setFields] = useState([])
    const [jenisForm, setJenisForm] = useState("")
    const [transisi, setTransisi] = useState("")
    const [tipeForm, setTipeForm] = useState("")
    const [tumpukDenganProsedur, setTumpukDenganProsedur] = useState("")
    const [selectedForUpdateField, setSelectedForUpdateField] = useState({})
    const [defaultFieldValue, setDefaultFieldValue] = useState("")
    const [selectedLabel, setSelectedLabel] = useState("")
    const [selectedTipe, setSelectedTipe] = useState("")
    const [visible, setVisible] = useState(false)
    const dispatch = useDispatch()

    const handleDelete = async () => {
        try {
            deleteItem(id)
        } catch (err) {
            console.log(err)
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const removeFieldHandle = async (record) => {
        await dispatch(deleteFormItem(record._id))
        getDataById(id)
    }

    const onFinish = async (values) => {
        if (id) {
            updateItem(values)
        } else {
            addItem(values)
        }
    }

    const handleTambahFieldOk = async () => {
        await dispatch(addFormItem({
            formId: id,
            label: selectedLabel,
            type: selectedTipe,
            default_value: defaultFieldValue
        }))
        setSelectedLabel("")
        setSelectedTipe("")
        setDefaultFieldValue("")
        getDataById(id)
        setTambahFieldVisible(false)
    }

    const handleTambahFieldCancel = (values) => {
        console.log(values)
        setTambahFieldVisible(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const addItem = async (values) => {
        try {
            await dispatch(addForm({ ...values }))
            message.success("Form Berhasil Dibuat! Kembali Ke Halaman Depan...")
            setTimeout(() => {
                history.push({
                    pathname: '/app/form'
                })
            }, 1000)
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const updateItem = async (values) => {
        try {
            await dispatch(updateForm({ ...values, id: id, tipeForm, transisi, editable }))
            message.success("Form berhasil diupdate!")
            history.push("/app/form")
        }
        catch (err) {
            message.error("Terjadi kesalahan, hubungi admin...")
        }
    }

    const getDataById = async (id) => {
        try {
            const item = await dispatch(fetchOneForm(id)).unwrap()
            const formItem = await dispatch(fetchOneByFormId(id)).unwrap()
            form.setFieldsValue(item[0])
            setTransisi(item[0].transisi)
            setEditable(item[0].editable)
            setFields(formItem)
            setJenisForm(item)
        } catch (err) {
            console.log(err)
        }
    }

    const deleteItem = async (id) => {
        await dispatch(deleteForm(id))
        message.success("Proses Hapus data...")
        setTimeout(() => {
            history.push({
                pathname: '/app/form'
            })
        }, 1000)
    }

    const renderDefault = (tipe, value) => {
        if (tipe === "Markdown") {
            return (
                <CKEditor
                    editor={ClassicEditor}
                    style={{ height: "50em" }}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setDefaultFieldValue(data)
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />
            )
        } else {
            return (
                <Input type='text' ></Input>
            )
        }
    }

    useEffect(() => {
        const itemId = location.state?.id
        if (itemId) {
            setId(itemId)
            getDataById(itemId)
        }
    }, [])

    const tableColumns = [
        {
            title: () => <div className="text-center">Nama Field</div>,
            dataIndex: 'label',
            key: 'label',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.label}</div>)
            },
        },
        {
            title: () => <div className="text-center">Tipe Field</div>,
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.type}</div>)
            },
        },
        {
            title: () => <div className="text-center">Default Value</div>,
            dataIndex: 'default_value',
            key: 'default_value',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.default_value ? "Ada" : "Tidak Ada"}</div>)
            },
        },
        {
            title: () => <div className="text-center">Edit Value</div>,
            dataIndex: 'edit',
            key: 'edit',
            render: (_, record) => {
                return (
                    <div style={{ display: "flex" }}>
                        <Button type="primary" style={{ ...buttonStlye, color: "white", width: "100%" }} htmlType="submit" onClick={() => {
                            history.push("/app/detail-form-item", {
                                state: {
                                    id: record._id,
                                    formId: id
                                }
                            })
                        }} block>
                            Edit
                        </Button>
                    </div>
                )
            },
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'detail',
            render: (_, record) => {
                return (
                    <div style={{ display: "flex" }}>
                        <Button type="danger" style={{ ...buttonStlye, color: "white", width: "100%" }} htmlType="submit" onClick={() => {
                            // removeField(record)
                            removeFieldHandle(record)
                        }} block>
                            Delete
                        </Button>
                    </div>
                )
            },
        }
    ]

    const editValue = async (record) => {
        const formItem = await dispatch(fetchOneFormItem(record._id)).unwrap()
        setSelectedForUpdateField(formItem[0])
        setEditFieldVisible(true)
    }

    const handleEditFieldOk = (record) => {

    }

    const handleEditFieldCancel = (record) => {
        setEditFieldVisible(false)
    }

    return (
        <>
            <Modal
                title="Hapus Data"
                visible={visible}
                onOk={handleDelete}
                onCancel={handleCancel}
            >
                <p>Anda yakin ingin melakukan aksi ini?</p>
            </Modal>
            <Modal
                title="Tambah Field"
                width={"100%"}
                visible={tambahFieldVisible}
                onOk={handleTambahFieldOk}
                onCancel={handleTambahFieldCancel}>
                <Card>
                    <Form
                        onFinish={handleTambahFieldOk}
                    >

                        <Form.Item
                            name="label"
                            label="Nama"
                        >
                            <Input onChange={(value) => { setSelectedLabel(value.target.value) }} style={{ width: "100%", color: "grey" }}></Input>
                        </Form.Item>
                        <Form.Item
                            name="tipe"
                            label="Tipe"
                        >
                            <Select onChange={(value) => { setSelectedTipe(value) }} style={{ width: "100%", color: "grey" }}>
                                <Option value={"Text"}>Text</Option>
                                <Option value={"Boolean"}>Boolean</Option>
                                <Option value={"Number"}>Number</Option>
                                <Option value={"File"}>File</Option>
                                <Option value={"Markdown"}>Markdown</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="default_field"
                            label="Default Value"
                        >
                            {renderDefault(selectedTipe)}
                            <Form.Item>
                                <br></br>
                                <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
                                    Tambah Data
                                </Button>
                            </Form.Item>
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>
            <Modal
                title="Edit Field"
                width={"100%"}
                visible={editFieldVisible}
                onOk={handleEditFieldOk}
                onCancel={handleEditFieldCancel}>
                <Form>
                    <Form.Item
                        name={selectedForUpdateField.label}
                        label="Nama"
                    >
                        <Input value={selectedForUpdateField.label} onChange={(value) => { setSelectedLabel(value.target.value) }} style={{ width: "100%", color: "grey" }}></Input>
                    </Form.Item>
                    <Form.Item
                        name={selectedForUpdateField.default_value}
                        label="Default Value"
                    >
                        {renderDefault(selectedForUpdateField.type)}
                        <Form.Item>
                            <br></br>
                            <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
                                Update Field
                            </Button>
                        </Form.Item>
                    </Form.Item>
                </Form>
            </Modal>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Form</h2>
                    <p>Tambah atau edit form sesuaikan dengan kebutuhan anda</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Form
                            form={form}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                name="urutan"
                                label="Urutan"
                            >
                                <Input style={{ width: "100%", color: "grey" }}></Input>
                            </Form.Item>

                            <Form.Item
                                label="Kode Form"
                                name="code"
                                style={{ width: "100%", color: "#455560" }}
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Nama Form"
                                name="name"
                                style={{ width: "100%", color: "#455560" }}
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="editable"
                                label="Bisa Diedit Secara Paralel"
                            >
                                <Select
                                    placeholder={"Iya/Tidak"}
                                    value={editable}
                                    showSearch
                                    style={{ width: "100%", color: "#455560" }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onChange={(value) => { setEditable(value) }}>
                                    <Option value={"Iya"} >Iya</Option>
                                    <Option value={"Tidak"} >Tidak</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="jenis_form"
                                label="Jenis Form"
                            >
                                <Select
                                    placeholder={"Jenis Form"}
                                    value={jenisForm}
                                    showSearch
                                    style={{ width: "100%", color: "#455560" }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onChange={(value) => { setJenisForm(value) }}>
                                    <Option value={"Pemeriksaan"} >Pemeriksaan</Option>
                                    <Option value={"Penimbangan"} >Penimbangan</Option>
                                    <Option value={"Pengolahan"} >Pengolahan</Option>
                                    <Option value={"Pengemasan"} >Pengemasan</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="tipe_form"
                                label="Tipe Form"
                            >
                                <Select
                                    placeholder={"Tipe Form"}
                                    value={tipeForm}
                                    showSearch
                                    style={{ width: "100%", color: "#455560" }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onChange={(value) => { setTipeForm(value) }}>
                                    <Option value={'CPB'} >CPB</Option>
                                    <Option value={'Lampiran'} >Lampiran</Option>
                                    <Option value={'Lainnya'} >Lainnya</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="tampilkan_prosedur"
                                label="Tampilkan Prosedur"
                            >
                                <Select
                                    placeholder={"Iya/Tidak"}
                                    value={tampilkanProsedur}
                                    showSearch
                                    style={{ width: "100%", color: "#455560" }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onChange={(value) => { setTampilkanProsedur(value) }}>
                                    <Option value={"Iya"} >Iya</Option>
                                    <Option value={"Tidak"} >Tidak</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="tumpuk_dengan_prosedur"
                                label="Tumpuk Dengan Prosedur"
                            >
                                <Select
                                    placeholder={"Iya/Tidak"}
                                    value={tumpukDenganProsedur}
                                    showSearch
                                    style={{ width: "100%", color: "#455560" }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onChange={(value) => { setTumpukDenganProsedur(value) }}>
                                    <Option value={"Iya"} >Iya</Option>
                                    <Option value={"Tidak"} >Tidak</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="transisi"
                                label="Transisi Setelah Approved"
                            >
                                <Select
                                    placeholder={"Transisi"}
                                    value={tumpukDenganProsedur}
                                    showSearch
                                    style={{ width: "100%", color: "#455560" }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onChange={(value) => { setTransisi(value) }}>
                                    <Option value={"Pemeriksaan"} >Pemeriksaan</Option>
                                    <Option value={"Penimbangan"} >Penimbangan</Option>
                                    <Option value={"Pengolahan"} >Pengolahan</Option>
                                    <Option value={"Pengemasan"} >Pengemasan</Option>
                                    <Option value={"Selesai"} >Selesai</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
                                    {id ? "Update" : "Tambah"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            {id && (
                <>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <h2>Field di Form Ini</h2>
                            <p>Semua field di form ini</p>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Card>
                                <div style={{ display: "flex" }}>
                                    <Input
                                        placeholder="Cari Berdasarkan Nama"
                                        onChange={() => { }}
                                        style={{
                                            width: "80%",
                                            margin: "10px"
                                        }}
                                    />
                                    <Button type="primary" style={{ margin: "10px" }} onClick={() => { }}>Cari</Button>
                                    <Button type="primary" style={{ margin: "10px" }} onClick={() => { setTambahFieldVisible(true) }}>Tambah Field</Button>
                                </div>
                                <Table
                                    className="no-border-last"
                                    columns={tableColumns}
                                    loading={false}
                                    dataSource={fields}
                                    rowKey='id'
                                />
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Button type="danger" style={{ border: "0px", color: "white" }} htmlType="submit" onClick={() => {
                                setVisible(true)
                            }} block>
                                Hapus
                            </Button>
                        </Col>
                    </Row>
                </>
            )}

            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px", color: "white", marginTop: "10px" }} htmlType="submit" onClick={() => {
                        // setVisible(true)
                        history.push("/app/form")
                    }} block>
                        Kembali Ke Halaman Utama
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default DETAIL_BAHAN
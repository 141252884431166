import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'
import jwt_decode from 'jwt-decode'
import moment from "moment"

export const fetchAllHistory = createAsyncThunk(
	'History/fetchAllHistory',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.HISTORY,payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchAllByDate = createAsyncThunk(
	'History/fetchAllByDate',
	async (date, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.HISTORY + "/date/" + date)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchOneHistory = createAsyncThunk(
	'History/fetchOneHistory',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.HISTORY}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const addHistory = createAsyncThunk(
	'History/addHistory',
	async (payload, { rejectWithValue }) => {
		try {
			let token = localStorage.getItem("token")
            let user = jwt_decode(token)
			const response = await request('post', URLS.HISTORY, {
				...payload,
				date:moment().unix(),
                tanggal_dibuat:moment().format("DD-MM-YYYY"),
				writtenBy:user._id
			})
			console.log({
				history: response
			})
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const deleteHistory = createAsyncThunk(
	'History/deleteHistory',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('delete', `${URLS.HISTORY}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const updateHistory = createAsyncThunk(
	'History/updateHistory',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', URLS.HISTORY, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const initialState = {
	loading: {
		query: false,
		mutation: false
	},
	filter: {
		q: ''
	},
	list: [],
	selected: {},
	selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
	state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const HistorySlice = createSlice({
	name: 'History',
	initialState,
	reducers: {
		setAppliedSearchText: (state, action) => {
			state.filter.q = action.payload
		},
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAllHistory.pending, startLoadingQuery)
			.addCase(fetchAllHistory.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllHistory.rejected, stopLoadingQuery)
		builder
			.addCase(fetchAllByDate.pending, startLoadingQuery)
			.addCase(fetchAllByDate.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllByDate.rejected, stopLoadingQuery)
		builder
			.addCase(fetchOneHistory.pending, startLoadingQuery)
			.addCase(fetchOneHistory.rejected, stopLoadingQuery)
			.addCase(fetchOneHistory.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(updateHistory.pending, startLoadingQuery)
			.addCase(updateHistory.rejected, stopLoadingQuery)
			.addCase(updateHistory.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(deleteHistory.pending, startLoadingMutation)
			.addCase(deleteHistory.fulfilled, stopLoadingMutation)
			.addCase(deleteHistory.rejected, stopLoadingMutation)
	}
});


export const { setSelectedRows, setAppliedSearchText } = HistorySlice.actions;

export default HistorySlice.reducer;
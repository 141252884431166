import { Button, Card, Col, DatePicker, Input, Row, Select, Table, message } from 'antd';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { deleteBatchReport, fetchAllBatchReport, fetchAllBatchReportByDate } from 'redux/features/batch-report';
import moment from "moment"

const TAMBAH_BATCH = ["admin", "rd_staff", "chief_rd", "rd_spv", "warehouse_manager", "production_development_manager", "quality_regulatory_manager", "qa_spv", "qa_staff"]
const RND = ["rd_staff", "chief_rd", "rd_spv"]
const SEARCH_ACCESS = ["production_development_manager", "admin", "rd_staff", "chief_rd", "rd_spv", "warehouse_manager", "production_development_manager", "quality_regulatory_manager", "qa_spv", "qa_staff"]
const PENIMBANGAN = ["operator_penimbangan"]
const PENGOLAHAN = ["operator_pengolahan"]
const PENGEMASAN = ["operator_pengemasan"]
const OPERATOR = ["operator_penimbangan", "operator_pengemasan", "operator_pengolahan",'operator_cutting','operator_extruder']

const Option = Select

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const BatchReport = () => {
    const history = useHistory()
    const [isLoading] = useState(false)
    const dispatch = useDispatch()
    const [user, setUser] = useState()
    const [query, setQuery] = useState({})
    const [list, setList] = useState([])

    const getData = useCallback(async () => {
        try {
            const response = await dispatch(fetchAllBatchReportByDate(moment().format("DD-MM-YYYY"))).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    // const getDataByDate = async (date) => {
    //     try {
    //         const data = await dispatch(fetchAllBatchReportByDate(date)).unwrap()
    //         console.log(data)
    //     } catch (error) {
    //         message.error(error?.message || 'Failed to fetch data')
    //     }
    // }

    const cari = async () => {
        const response = await dispatch(fetchAllBatchReport(query)).unwrap()
        setList(response)
        setQuery({})
    }

    const getByJenis = async (jenis) => {
        const response = await dispatch(fetchAllBatchReport({
            batch_step: jenis
        })).unwrap()
        setList(response)
        setQuery({})
    }

    useEffect(() => {
        getUserData()
    }, [])

    const getUserData = async () => {
        try {
            let token = localStorage.getItem("token")
            let user = jwt_decode(token)
            setUser(user)
            if (PENIMBANGAN.includes(user.role)) {
                getByJenis("Penimbangan")
            } else if (PENGOLAHAN.includes(user.role)) {
                getByJenis("Pengolahan")
            } else if (PENGEMASAN.includes(user.role)) {
                getByJenis("Pengemasan")
            } else {
                getData()
            }
        } catch (err) {
            console.log(err)
        }
    }

    const tableColumns = [
        // {
        //     title: () => <div className="text-center">Nomor Batch</div>,
        //     dataIndex: '_id',
        //     key: '_id',
        //     render: (_, record) => {
        //         return (<div style={{ textAlign: "center" }}>{record._id}</div>)
        //     },
        // },
        {
            title: () => <div className="text-center">Nomor Batch</div>,
            dataIndex: 'nomor',
            key: 'nomor',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.nomor ? record?.nomor : 'Belum Diatur'}</div>)
            },
        },
        {
            title: () => <div className="text-center">Nama Produk</div>,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.name}</div>)
            },
        },
        {
            title: () => <div className="text-center">PIC</div>,
            dataIndex: 'created_by',
            key: 'created_by',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.created_by[0]?.fullname || "Admin"}</div>)
            },
        },
        {
            title: () => <div className="text-center">Dibuat Tanggal</div>,
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{moment(record.created_at, 'X').format('lll')}</div>)
            },
        },
        {
            title: () => <div className="text-center">Terakhir Update</div>,
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{moment(record.updated_at, 'X').format('lll')}</div>)
            },
        },
        {
            title: () => <div className="text-center">Berat</div>,
            dataIndex: 'berat',
            key: 'berat',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{numberWithCommas(record.berat_total)}</div>)
            },
        },
        {
            title: () => <div className="text-center">Posisi Proses</div>,
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                return (<div style={{ textAlign: "center", color: record.status === "DONE" ? "green" : "red" }}>{record?.status}</div>)
            },
        },
        {
            title: () => <div className="text-center">Status Batch</div>,
            dataIndex: 'status_batch',
            key: 'status_batch',
            render: (_, record) => {
                return (<div style={{ textAlign: "center", color: record.status_batch === "READY" ? "green" : "orange" }}>{record.status_batch}</div>)
            },
        },
        // {
        //     title: () => <div className="text-center">Batch Step</div>,
        //     dataIndex: 'batch_step',
        //     key: 'batch_step',
        //     render: (_, record) => {
        //         return (<div style={{ textAlign: "center",color:"black"}}>{record?.batch_step}</div>)
        //     },
        // },
        {
            title: () => <div className="text-center">Form Terisi</div>,
            dataIndex: 'forms',
            key: 'forms',
            render: (_, record) => {
                return (<div style={{ textAlign: "center" }}>{record.forms?.length}</div>)
            },
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'detail',
            render: (_, record) => {
                if(!OPERATOR.includes(user.role)){
                    if (record.status_batch === "READY") {
                        return (
                            <Button type="primary" style={{ border: "0px", width: "100%" }} htmlType="submit" onClick={() => {
                                history.push({
                                    pathname: '/app/detail-batch-report',
                                    state: {
                                        batchId: record._id
                                    }
                                })
                            }} block>
                                Detail
                            </Button>
                        )
                    } else {
                        return (
                            <Button type="primary" style={{ border: "0px",background:"orange" }} htmlType="submit" onClick={() => {
                                history.push({
                                    pathname: '/app/tambah-batch',
                                    state: {
                                        batchId: record._id
                                    }
                                })
                            }} block>
                                Edit Batch
                            </Button>
                        )
                    }
                }
            },
        },
        {
            title: () => <div className="text-center">Action</div>,
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => {
                if (RND.includes(user?.role)) {
                    return (<Button danger onClick={() => {
                        deleteBatchReportClick(record._id)
                    }}>Delete</Button>)
                } else {
                    if (record.status_batch === "READY") {
                        return (
                            <Button type="primary" style={{ border: "0px", width: "100%" }} htmlType="submit" onClick={() => {
                                history.push({
                                    pathname: '/app/detail-batch-report',
                                    state: {
                                        batchId: record._id
                                    }
                                })
                            }} block>
                                View Only
                            </Button>
                        )
                    } else {
                        return (
                            <div style={{ textAlign: "center", color: "red" }}>{record.status_batch}</div>
                        )
                    }
                }

            },
        },
    ];

    const onChangeDate = async (value) => {
        if (value) {
            setQuery(prevQuery => ({
                ...prevQuery,
                tanggal_dibuat: moment(value).format("DD-MM-YYYY")
            }))
        } else {
            message.error("Tanggal tidak valid!")
        }
    }

    const onChangeTitle = async (event) => {
        event.persist();
        setQuery(prevQuery => ({
            ...prevQuery,
            name: event?.target?.value || ""
        }))
    }

    const onChangeBatchStep = async (value) => {
        setQuery(prevQuery => ({
            ...prevQuery,
            batch_step: value
        }))
    }

    const deleteBatchReportClick = async (id) => {
        await dispatch(deleteBatchReport(id))
        getData()
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Batch Report di Tanggal: {moment().format("DD-MM-YYYY")}</h2>
                    <p>Akun ini login sebagai <b style={{ color: "black" }}>{user?.email} </b>akses untuk role <b style={{ color: "black" }}>{user?.role} </b></p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <DatePicker
                                placeholder="Cari Berdasarkan Tanggal"
                                name='date'
                                onChange={onChangeDate}
                                format="DD-MM-YYYY"
                                style={{
                                    width: "30%",
                                    marginRight: "2%"
                                }}
                            />
                            <Input
                                name="name"
                                onChange={onChangeTitle} style={{
                                    width: "40%",
                                    marginRight: "2%"
                                }} placeholder='Nama Produk'></Input>
                            <Select onChange={onChangeBatchStep}
                                style={{
                                    width: "30%",
                                    color: "black",
                                    marginRight: "2%"
                                }} placeholder='Status'>
                                <Option value={"Penimbangan"}>Penimbangan</Option>
                                <Option value={"Pengolahan"}>Pengolahan</Option>
                                <Option value={"Pengemasan"}>Pengemasan</Option>
                                <Option value={"Selesai"}>Selesai</Option>
                            </Select>
                            <Button type="primary" style={{ width: "10%" }} onClick={cari}>Search</Button>
                            {/* <Button type="danger"  style={{ width: "10%", color:"white" }} onClick={()=>{
                                setQuery({})
                            }}>Reset</Button> */}
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            loading={isLoading}
                            rows={5}
                            scroll={{ x: 1000 }}
                            dataSource={list}
                            rowKey='id'
                        />
                    </Card>
                </Col>
            </Row>
            {TAMBAH_BATCH.includes(user?.role) && (
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                            history.push({
                                pathname: '/app/tambah-batch'
                            })
                        }} block>
                            Tambah Batch Report
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    )
}


export default withRouter(BatchReport);

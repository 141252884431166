import moment from "moment-timezone";

export const strings = {
  today: moment().tz("Asia/Jakarta").format("YYYY-MM-DD"),
  tomorrow: moment().add(1, 'days').tz("Asia/Jakarta").format("YYYY-MM-DD"),
  token: localStorage.getItem('token'),
  api: {
    host: "https://adev-ecpb.gawegawe.id",
    localhost: "https://adev-ecpb.gawegawe.id",
    prodhost: "https://adev-ecpb.gawegawe.id",
    HEADER_KEY: "mantapjiwa",
    JWT_KEY: "Aj1257Xi202",
  },
  image: {
    authBackground: 'url(/img/background.png)',
    logo: "/img/logo.png",
    logo2: "/img/logo-colored.png",
    favicon: "",
    primaryColor: "#1445e4",
    buttonColor: "#6e17f7",
    color1: "#0675b5",
    color2: "#10a8f0",
    color3: "#0077b6",
    color4: "#FF0000",
    color5: "#005578",
    color6: "#FFAA00"
  },
  auth_form: {
    isUnavailableAccount: "Belum punya akun? ",
    sign_up: "Daftar",
    sign_in: "Masuk",
    alreadyHaveAccount: "Sudah punya akun? ",
    create_account: "Create a new account:",
  },
  default: {
    title_app: "Adev Dashboard",
  },
  navigation: {
    login: '/auth/login',
    register: '/auth/register',
    main: '/app',
    path: {
      // auth
      login: 'login',
      register: 'register',
      forgot_password: 'forgot-password',
      activate: 'activate',
      resend:"resend",
      please: 'please',
      logout: "logout",
      // apps
      dashboard: "/app/dashboard",
      batch: "/app/batch",
      batch_report: "/app/batch-report",
      form_report: "/app/form-report",
      produk: "/app/produk",
      pengguna: "/app/pengguna",
      laporan: "/app/laporan",
      formula: "/app/formula",
      form: "/app/form",
      form_report: "/app/form-report",
      master_data: "/app/master-data",
      report: "/app/report",
      history: "/app/history",
      batch_report: "/app/batch-report",
      form_report: "/app/form-report",
      bahan: "/app/bahan",
      // detail
      detail_batch: "/app/detail-batch",
      detail_form: "/app/detail-form",
      detail_form_item: "/app/detail-form-item",
      detail_formula: "/app/detail-formula",
      detail_history:"/app/detail-history",
      detail_product: "/app/detail-product",
      detail_laporan: "/app/detail-laporan",
      detail_bahan: "/app/detail-bahan",
      detail_pengguna: "/app/detail-pengguna",
      detail_batch_report: "/app/detail-batch-report",
      detail_form_report: "/app/detail-form-report",

      // tambah
      tambah_batch:"/app/tambah-batch",

      // settings
      profile: "/app/profile",
      settings: "/app/settings"
    }
  }
};

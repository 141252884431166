import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllBahan = createAsyncThunk(
	'Bahan/fetchAllBahan',
	async (_, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.BAHAN)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const searchBahan = createAsyncThunk(
	'Bahan/searchBahan',
	async (title, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.BAHAN}/search?name=${title}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchOneBahan = createAsyncThunk(
	'Bahan/fetchOneBahan',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.BAHAN}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const addBahan = createAsyncThunk(
	'Bahan/addBahan',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('post', URLS.BAHAN, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const updateBahan = createAsyncThunk(
	'Bahan/updateBahan',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await request('patch', URLS.BAHAN, payload)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const deleteBahan = createAsyncThunk(
	'Bahan/deleteBahan',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('delete', `${URLS.BAHAN}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const initialState = {
	loading: {
		query: false,
		mutation: false
	},
	filter: {
		q: ''
	},
	list: [],
	selected: {},
	selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
	state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const BahanSlice = createSlice({
	name: 'Bahan',
	initialState,
	reducers: {
		setAppliedSearchText: (state, action) => {
			state.filter.q = action.payload
		},
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAllBahan.pending, startLoadingQuery)
			.addCase(fetchAllBahan.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllBahan.rejected, stopLoadingQuery)

		builder
			.addCase(fetchOneBahan.pending, startLoadingQuery)
			.addCase(fetchOneBahan.rejected, stopLoadingQuery)
			.addCase(fetchOneBahan.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(updateBahan.pending, startLoadingQuery)
			.addCase(updateBahan.rejected, stopLoadingQuery)
			.addCase(updateBahan.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(searchBahan.pending, startLoadingQuery)
			.addCase(searchBahan.rejected, stopLoadingQuery)
			.addCase(searchBahan.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})

		builder
			.addCase(deleteBahan.pending, startLoadingMutation)
			.addCase(deleteBahan.fulfilled, stopLoadingMutation)
			.addCase(deleteBahan.rejected, stopLoadingMutation)
	}
});


export const { setSelectedRows, setAppliedSearchText } = BahanSlice.actions;

export default BahanSlice.reducer;
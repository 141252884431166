import { all, management, formula, rnd, report } from 'utils/roles';
import { strings } from 'res/strings/index'
import { roles } from "../constants/RoleConstant"
import { AiOutlineDashboard, AiOutlineCalendar, AiOutlineAlert, AiOutlineBarChart, AiOutlineDatabase, AiOutlineUser, AiOutlineSetting, AiOutlineAlignCenter, AiOutlineBook, AiOutlineAccountBook } from "react-icons/ai";

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    // {
    //   key: "Dashboard",
    //   path: strings.navigation.path.dashboard,
    //   title: "Dashboard",
    //   icon: AiOutlineDashboard,
    //   breadcrumb: false,
    //   access: management,
    //   submenu: []
    // },
    {
      key: "Report",
      path: strings.navigation.path.report,
      title: "Report",
      icon: AiOutlineCalendar,
      breadcrumb: false,
      access: report,
      submenu: []
    },
    // {
    //   key: "Batch",
    //   path: strings.navigation.path.batch,
    //   title: "Batch",
    //   icon: AiOutlineBook,
    //   breadcrumb: false,
    //   access: management.concat(rnd),
    //   submenu: []
    // },
    {
      key: "Form",
      path: strings.navigation.path.form,
      title: "Form",
      icon: AiOutlineAccountBook,
      breadcrumb: false,
      access: management.concat(rnd),
      submenu: []
    },
    {
      key: "Produk",
      path: strings.navigation.path.produk,
      title: "Produk",
      icon: AiOutlineDatabase,
      breadcrumb: false,
      access: management.concat(rnd),
      submenu: []
    },
    {
      key: "Formula",
      path: strings.navigation.path.formula,
      title: "Formula",
      icon: AiOutlineBarChart,
      access: formula,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Bahan Baku",
      path: strings.navigation.path.bahan,
      title: "Bahan Baku",
      icon: AiOutlineAlignCenter,
      breadcrumb: false,
      access: management.concat(rnd),
      submenu: []
    },
    {
      key: "History",
      path: strings.navigation.path.history,
      title: "History",
      icon: AiOutlineAlert,
      breadcrumb: false,
      access: roles,
      submenu: []
    },
    {
      key: "Pengguna",
      path: strings.navigation.path.pengguna,
      title: "Pengguna",
      icon: AiOutlineUser,
      breadcrumb: false,
      access: ["admin"],
      submenu: []
    }
  ]
}]

const ControlTree = [{
  key: "Pengaturan",
  path: strings.navigation.path.settings,
  title: "Pengaturan",
  access: roles,
  breadcrumb: false,
  submenu: [
    {
      key: "Pengaturan",
      path: strings.navigation.path.settings,
      title: "Pengaturan",
      icon: AiOutlineSetting,
      breadcrumb: false,
      access: roles,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...ControlTree,
]

export default navigationConfig;
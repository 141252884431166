import { Button, Card, Col, DatePicker, Input, Row, Table, message } from 'antd';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { fetchAllBatch } from 'redux/features/batch';
import jwt_decode from 'jwt-decode'
import { fetchOneBatchReport } from 'redux/features/batch-report';
import { fetchAllByBatchId } from 'redux/features/form-report';
import { addHistory } from 'redux/features/history';

const STATUS = ["ON PROCESS", "ON REVIEW","APPROVED", "REVISION", "REJECTED", "DRAFT"]
const AKSES_PEMERIKSAAN = ["qa_staff",'in_process_control_staff',"chief_produksi_pengemasan","admin", "warehouse_manager", "production_development_manager", "quality_regulatory_manager", "rd_staff", "chief_rd", "rd_spv", "quality_control_spv", "chief_qc", "chief_produksi"]
const AKSES_PENIMBANGAN = ["qa_staff",'in_process_control_staff',"chief_produksi_pengemasan","admin", "warehouse_manager", "production_development_manager", "quality_regulatory_manager", "rd_staff", "chief_rd", "rd_spv", "quality_control_spv", "chief_qc", "chief_produksi", "operator_penimbangan"]
const AKSES_PENGOLAHAN = ["qa_staff",'in_process_control_staff','operator_extruder','operator_cutting',"chief_produksi_pengemasan","admin", "warehouse_manager", "production_development_manager", "quality_regulatory_manager", "rd_staff", "chief_rd", "rd_spv", "quality_control_spv", "chief_qc", "chief_produksi", "operator_pengolahan"]
const AKSES_PENGEMASAN = ["qa_staff",'in_process_control_staff',"chief_produksi_pengemasan","admin", "warehouse_manager", "production_development_manager", "quality_regulatory_manager", "rd_staff", "chief_rd", "rd_spv", "quality_control_spv", "chief_qc", "chief_produksi", "operator_pengemasan"]
const OPERATOR = ["operator_penimbangan", "operator_pengemasan", "operator_pengolahan"]
const APPROVAL = ["admin"]

const renderButton = (props) => {
    const urutan = props.data?.urutan
    // const editable = props.data?.editable
    const status = props.formReports[urutan - 1]?.status
    // const prevEditable = props.formReports[urutan - 1]?.editable === "Iya"
    const prevEditable = props.formReports[urutan - 2]?.form?.editable === "Iya"
    const prevStatus = props.formReports[urutan - 2]?.status
    if ((urutan >= props.formReports?.length + 1) && (prevStatus === STATUS[2])) {
        return (
            <>
                <Button onClick={() => {
                    props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        status: "Isi Form"
                    })
                }} type='primary' style={{ width: "100%" }} >Isi Form</Button>
                <Button onClick={() => {
                    props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        formReports: props.formReports,
                        status: "Detail"
                    })
                }} type='primary' style={{ width: "100%", marginTop: "10px" }} >Detail</Button>
            </>
        )
    }
    else if (status === STATUS[5]) {
        return (
            <>
                <Button onClick={() => {
                     props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        formReports: props.formReports,
                        status: "Detail"
                    })
                }} style={{ width: "100%",background:"orange",color:'white' }} >Lanjutkan Form</Button>
                <Button onClick={() => {
                    props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        formReports: props.formReports,
                        status: "Detail"
                    })
                }} type='primary' style={{ width: "100%", marginTop: "10px" }} >Detail</Button>
            </>
        )
    }
    else if (props.formReports?.length === 0) {
        return (
            <>
                <Button onClick={() => {
                    props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        status: "Isi Form"
                    })
                }} type='primary' style={{ width: "100%" }} >Isi Form</Button>
                <Button onClick={() => {
                    props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        formReports: props.formReports,
                        status: "Detail"
                    })
                }} type='primary' style={{ width: "100%", marginTop: "10px" }} >Detail</Button>
            </>
        )
    }else if (status === STATUS[1]) {
        return (
            <>
                <Button type='primary' style={{ width: "100%", background: "orange", border: "none" }} >On Review</Button>
                <Button onClick={() => {
                    props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        formReports: props.formReports,
                        status: "Detail"
                    })
                }} type='primary' style={{ width: "100%", marginTop: "10px" }} >Detail</Button>
            </>
        )
    }else if (status === STATUS[2]) {
        return (
            <>
                <Button type='primary' style={{ width: "100%", background: "green", color: "white", border: "none" }} >Approved</Button>
                <Button onClick={() => {
                    props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        formReports: props.formReports,
                        status: "Detail"
                    })
                }} type='primary' style={{ width: "100%", marginTop: "10px" }} >Detail</Button>
            </>
        )
    }else if (status === STATUS[3]) {
        return (
            <>
                <Button type='primary' style={{ width: "100%" }} >Revision</Button>
                <Button onClick={() => {
                    props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        formReports: props.formReports,
                        status: "Detail"
                    })
                }} type='primary' style={{ width: "100%", marginTop: "10px" }} >Detail</Button>
            </>
        )
    }else if (status === STATUS[4]) {
        return (
            <>
                <Button type='primary' style={{ width: "100%",background: "red",border: "none", color: "white" }} >Rejected</Button>
                <Button onClick={() => {
                    props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        formReports: props.formReports,
                        status: "Detail"
                    })
                }} type='primary' style={{ width: "100%", marginTop: "10px" }} >Detail</Button>
            </>
        )
    }else {
        if(prevEditable){
            return (
                <>
                    <Button onClick={() => {
                        props.history.push("/app/detail-form-report", {
                            batch: props.batch,
                            form: props.data,
                            status: "Isi Form"
                        })
                    }} type='primary' style={{ width: "100%" }} >Isi Form</Button>
                    <Button onClick={() => {
                        props.history.push("/app/detail-form-report", {
                            batch: props.batch,
                            form: props.data,
                            formReports: props.formReports,
                            status: "Detail"
                        })
                    }} type='primary' style={{ width: "100%", marginTop: "10px" }} >Detail</Button>
                </>
            )
        }else{
            return (
                <>
                    <Button onClick={() => {
                        props.history.push("/app/detail-form-report", {
                            batch: props.batch,
                            form: props.data,
                            status: "Isi Form"
                        })
                    }} disabled type='primary' style={{ width: "100%" }} >Isi Form</Button>
                    <Button onClick={() => {
                        props.history.push("/app/detail-form-report", {
                            batch: props.batch,
                            form: props.data,
                            formReports: props.formReports,
                            status: "Detail"
                        })
                    }} disabled type='primary' style={{ width: "100%", marginTop: "10px" }} >Detail</Button>
                </>
            )
        }
    }

}

const FormCard = (props) => {

    const urutan = props.data?.urutan
    const previousUrutan = props.data?.urutan - 1
    const currentStatus = props.formReports[urutan]?.status
    const previousStatus = props.formReports[previousUrutan]?.status
    const isRender = parseInt(props.data?.urutan) <= parseInt(props.formReports?.length + 1)
    if (isRender) {
        return (
            <Card title={props.data?.name} bordered={false}>
                <div>Urutan: {props.data?.urutan}</div>
                <div>
                    <span >Code: </span><span style={{ fontWeight: "bold", color: "gray" }}>{props.data.code}</span>
                </div>
                
                <div>
                    <span>Created By: </span><span style={{ fontWeight: "bold", color: props.formReports[props.data?.urutan-1]?.created_by?.fullname ? "blue" : "red" }}>{props.formReports[props.data?.urutan-1]?.created_by?.fullname || "Belum Ada"}</span>
                </div>
                <div>
                    <span>Approved By: </span><span style={{ fontWeight: "bold", color: props.formReports[props.data?.urutan-1]?.approved_by?.fullname ? "blue" : "red" }}>{props.formReports[props.data?.urutan-1]?.approved_by?.fullname || "Belum Ada"}</span>
                </div>
                <br />
                <p>{props.data.date}</p>
                {renderButton(props)}

            </Card>
        )
    } else {
        return (
            <Card title={props.data.name} headStyle={{ color: "gray" }} bordered={false}>
                <div>Urutan: {props.data?.urutan}</div>
                <div>
                    <span style={{ color: "gray" }} >Code: </span><span style={{ fontWeight: "bold", color: "gray" }}>{props.data.code}</span>
                </div>
                <div>
                    <span>Created By: </span><span style={{ fontWeight: "bold", color: props.data.created_by ? "blue" : "red" }}>{props.data.created_by || "Belum Ada"}</span>
                </div>
                <div>
                    <span>Approved By: </span><span style={{ fontWeight: "bold", color: props.data.approved_by ? "blue" : "red" }}>{props.data.approved_by || "Belum Ada"}</span>
                </div>
                <br />
                <p style={{ color: "gray" }} >{props.data.date}</p>
                <Button disabled style={{ width: "100%" }} >Isi Form</Button>
                <Button onClick={() => {
                    props.history.push("/app/detail-form-report", {
                        batch: props.batch,
                        form: props.data,
                        formReports: props.formReports,
                        status: "Detail"
                    })
                }} disabled type='primary' style={{ width: "100%", marginTop: "10px" }} >Detail</Button>
            </Card>
        )
    }
}

const GridBatch = (props) => {
    if (props.access.includes(props.role || "admin")) {
        return (
            <>
                <br />
                <h4>{props.title}</h4>
                <Row gutter={24}>
                    {props.data.map(form => {
                        return (
                            <Col xs={24} sm={24} md={8} lg={8} >
                                <FormCard role={props.role} formReports={props?.formReports} batch={props.batch} history={props.history} data={form} ></FormCard>
                            </Col>
                        )
                    })}
                </Row>
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export const DetailBatchReport = () => {

    const location = useLocation()
    const [batch, setBatch] = useState({})
    const [penimbangan, setPenimbangan] = useState([])
    const [pemeriksaan, setPemeriksaan] = useState([])
    const [formReports, setFormReports] = useState([])
    const [pengolahan, setPengolahan] = useState([])
    const [pengemasan, setPengemasan] = useState([])
    const [user, setUser] = useState({
        role: "user"
    })
    const [formsApproved, setFormsApproved] = useState([])
    const dispatch = useDispatch()
    const history = useHistory()

    const getAllBatches = async () => {
        const batches = await dispatch(fetchAllBatch()).unwrap()
        setPenimbangan(batches[0].forms)
        setPengolahan(batches[1].forms)
        setPengemasan(batches[2].forms)
        setPemeriksaan(batches[3].forms)
    }

    const getUserData = async () => {
        try {
            let token = localStorage.getItem("token")
            let user = jwt_decode(token)
            setUser({
                ...user,
                role: user.role
            })
        } catch (err) {
            console.log(err)
        }
    }

    const getAllFormsByBatchId = async (id) => {
        const formReports = await dispatch(fetchAllByBatchId(id)).unwrap()
        setFormReports(formReports)
    }

    useEffect(() => {
        getUserData()
        getAllBatches()
        const batchId = location.state?.batchId
        if (batchId) {
            getBatchById(batchId)
            getAllFormsByBatchId(batchId)
        }
    }, [])

    const getBatchById = async (id) => {
        const response = await dispatch(fetchOneBatchReport(id)).unwrap()
        setBatch(response[0])
        setFormsApproved(response[0].forms_approved || [])
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Proses Batch Report Produk: {batch.name}</h2>
                    <p>Proses batch yang telah masuk dan dibutuhkan tindakan lanjut</p>
                    {/* <h4>Form Submitted: {formReports.length}</h4>
                    <h4>Form Approved: {formsApproved.length}</h4> */}
                </Col>
            </Row>
            <GridBatch role={user.role} access={AKSES_PEMERIKSAAN} formReports={formReports} formsApproved={formsApproved} batch={batch} title="Pemeriksaan" data={pemeriksaan} history={history} ></GridBatch>
            <GridBatch role={user.role} access={AKSES_PENIMBANGAN} formReports={formReports} formsApproved={formsApproved} batch={batch} title="Penimbangan" data={penimbangan} history={history} ></GridBatch>
            <GridBatch role={user.role} access={AKSES_PENGOLAHAN} formReports={formReports} formsApproved={formsApproved} batch={batch} title="Pengolahan" data={pengolahan} history={history} ></GridBatch>
            <GridBatch role={user.role} access={AKSES_PENGEMASAN} formReports={formReports} formsApproved={formsApproved} batch={batch} title="Pengemasan" data={pengemasan} history={history}></GridBatch>
        </>
    )
}


export default withRouter(DetailBatchReport);
